import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';
var bgimg1 = require("../../images/whoweare/BROUCHER_20240521_151046_0000-8.jpg");
var bgimg2 = require("../../images/whoweare/BROUCHER_20240521_151046_0000-8.jpg");

const CMS = function (props) {
let url=props?.url;

    useEffect(() => {
        console.log(props);
        window.scrollTo(0, 0)


    }, [url])


    return (
        <>
            <Header></Header>
            <section className="shop-details pt-90 about-top1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="image-brother">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/0001.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-6 about-brother-box">
                          <div className="about-broth">
                            <h1 className="about-brother">Brothers<br/> on a mission<br/> to help you to <br/>create your<br/> <spam>MASTERPIECE</spam> <br/>Your Dream Home</h1>  
                          </div>
                        </div>
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 pb-90 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                          <div className="about-broth whychoo-about">
                            <h1 className="about-brother">Who are we ?</h1>
                            <h3>We Are a Team of Young, Vigorous and Ambitious Brothers on a Mission to Help You to 
                            Beautify Your Home Abode with a Vision of Making Your Home Decor Experience Joyful, Exciting, 
                            Vibrant and Hassle Free. So Let's Join Hands Together with Your Dekobuddies to Help You to Create 
                            Your Masterpiece ' YOUR DREAM HOME </h3>
                          </div>
                        </div>
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 pb-90 about-top1 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                          <div className="about-broth whychoo-about">
                            <h1 className="about-brother">Our Roots and Legacy</h1>
                            <h2>Dekobuddy is a sister company of Maaria Rugs Private Limited</h2>
                            <h3>MRI was founded in 1997 with a vision and ambition of having a successful say in 
                            providing global recognition to our Indian Rug Industry.<br/> Since then MRI has been successfully exporting 
                            thousands of rugs to America,EU, Australia and was able to forge good customer relationship 
                            overseas with our fine quality products, affordable prices and overwhelming service experience.</h3>
                            <h3>And Now After Decorating Home Abroad,We Are All Set To Be The " Dekobuddies " of Our Compatriots.</h3>
                          </div>
                        </div>
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 about-top1 bg-dark-about">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-8">
                           <div className="image-brother">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/Production-under-direct-guidence-of-skilled-artisans.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-4">
                          <div className="about-broth mrg-pa">
                            <h1 className="about-brother23">Maaria Rugs</h1>
                          </div>
                          <div className="box-ass">
                          <h3 className="cnjncc">Weaving Luxury</h3>
                          <h3 className="font-waegingb">SINCE 1997</h3>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="">
                            <h1 className="list-line-add">Dekobuddy is a Domestic Arm of Maaria Rugs Pvt. Limited</h1>
                          </div>
                        </div>
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 pb-90 about-top1 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                    <div className="row">
                           <div className="col-md-12">
                           <div className="cocwcin">
                           <div className="Speciality-tag"><h1 className="Speciality">Our Speciality</h1></div>
                           <div className="Speciality-tag1"><h2 className="Bhadohi">Bhadohi's Hand Made Rugs & Carpets</h2></div>
                           <h3 className="skncncwi">....,crafted with centuries-old expertise and <br/>a dedication to perfection.</h3>
                           <h3>As a proud manufacturer from Bhadohi : The Carpet City, we bring forth the finest varities of rugs,each 
                           intricately woven to embody artistry and tradition.Our commitment to quality ensures that 
                           every piece reflects the rich heritage of our region,boasting unpralleled craftsmanship and luxurious texture.</h3>
                           <h3>Let Bhadohi's legacy of fine rugs adorn your interiors,transforming spaces into timeless reflections of elegance and style.</h3>
                           </div>
                           </div>
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 pb-90 about-top1 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                <h1 className="about-brother">..... TO BE CONTINUED</h1>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/7.jpg")} />
                               
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/8.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/9.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/10.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-3 p-r">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/11.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-3 p-l">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/6.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/1.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/2.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/3.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/4.jpg")} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="image-brother123">
                               <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/5.jpg")} />
                            </div>
                        </div>
                        
                        
                       
                        </div>
                </div>
            </section>
            <section className="shop-details pt-90 pb-90 about-top1 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                    <div className="row">
                           <div className="col-md-12">
                           <div className="about-broth whychoo-about">
                           <h1 className="about-brother">WE ARE HERE TO OFFER YOU</h1>
                           <h2 className="">THE BE STQUALITY PRODUCTS<br/>@<br/>THE MOST AFFORDABLE PRICES</h2>
                           </div>

                           <div className="box-cetefe">
                           <div className="box-about-in">
                                <h1 className="about-brother">HOW AND WHY?</h1>
                                <ul>
                                <li><img style={{  width: '30px', maxWidth: '100%'}} src={require("../../images/whoweare/check.png")} />BECAUSE WE HAVE IN HOUSE PRODUCTION FACILITY</li>
                                <li><img style={{  width: '30px', maxWidth: '100%'}} src={require("../../images/whoweare/shape.png")} />HENCENO MIDDLE MAN</li>
                                <li><img style={{  width: '30px', maxWidth: '100%'}} src={require("../../images/whoweare/check.png")} />SO NO EXTRA CHARGES</li>
                                </ul>
                              
                             </div>
                             </div>


                           </div>
                        </div>
                </div>
            </section>
            <section className="about-top1">
                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/whoweare/BROUCHER_20240521_151046_0000-9.jpg")} />
            </section>
            <section className="shop-details pt-90 pb-90 about-top1 mr-boutr" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                <div className="container">
                    <div className="row">
                           <div className="col-md-12">
                           <div className="cont-details">
                           <h1 className="about-brother">GET IN TOUCH WITH US</h1>
                           <ul>
                           <li><img style={{  width: '60px', maxWidth: '100%'}} src={require("../../images/whoweare/telephone.png")} />
                           <a href="tel:9935036578">9935036578</a></li>
                           <li><img style={{  width: '60px', maxWidth: '100%'}} src={require("../../images/whoweare/envelope.png")} />
                           <a href="mailto:rugsahad@gmail.com">rugsahad@gmail.com</a></li>
                           </ul>
                           </div>
                           <div className="shop-address">
                           <div className="shop-address-box">
                            <h4>Visit Our</h4>
                            <h2>SHOWROOM</h2>
                            <h5>Shop No. 315 B Sahara Plaza, Vikaskhand Gomtinagar</h5>
                            <h2>Lucknow</h2>
                           </div>
                           <div className="shop-address-box">
                            <h4>Visit Our</h4>
                            <h2>Company</h2>
                            <h5>221401<br/> Sarroi</h5>
                            <h2>BHADOHI</h2>
                           </div>
                           </div>
                           </div>
                        </div>
                </div>
            </section>

            <Footer></Footer>
        </>
    )

}
export default CMS