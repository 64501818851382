import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const RepairMaintenance = function () {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        
        <div className="container">
                    <div className="row">
                    <div className="col-md-1"></div>
                        <div className="col-md-10">
                         <div className="pad-csa-boder">
                         <h1 className="titektre">Repair Maintenance</h1>
                        
                           <p className="content-p">dekobuddy  is happy to help you with repair and maintenance services for your rugs.
Our professional repair staff has the expertise to repair even the finest of rugs. It is important to remember that the less damaged the rug is, the easier it is to restore and the less costly the repair.</p>
<p className="content-p"><strong>Our repair services include</strong></p>
<ul className="bullet-ul">
<li>Refringing, binding, or surging</li>
<li> Repairing of missing/worn areas by rebuilding or patching</li>
<li> Replacing torn, detached or deteriorated backing</li>
<li>Adding or removing fringes</li>
<li>Moth, mildew or water damage</li>
<li>Altering the rug's size and/or shape to fit your specifications</li>
</ul>
<p className="content-p"><strong>Procedure to get rug repaired</strong></p>
<p className="content-p">For rug repair services, customers are required to notify us via e-mail at <a href="mailto:dekobuddy@gmail.com">dekobuddy@gmail.com</a> or by call <a href="tel:919935036578">+919935036578 </a> with at least 2 photographs of the damaged rug(s). We will first examine each item and then will advise you with our recommendations and exact cost.</p>
<p className="content-p"><strong>Terms and Conditions</strong></p>
<ul className="bullet-ul">
<li> dekobuddy can arrange for pickup and delivery for your convenience</li>
<li>In case of a deal, the company will assume the logistics cost one-way</li>
<li>Valid only in India</li>
</ul>
 
                         </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
            
            <Footer></Footer>
        </>
    )

}
export default RepairMaintenance