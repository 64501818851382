
 
// import 'font-awesome/css/font-awesome.min.css';
//new import css...//
import React from 'react';
import './App.css';
import './css/preloader.css';
//import './css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/meanmenu.css';
import './css/animate.css';
import './css/owl-carousel.css';
import './css/swiper-bundle.css';
import './css/backtotop.css';
import './css/magnific-popup.css';
import './css/nice-select.css';
import './css/default.css';
//import './css/font-awesome-pro.css'; 
import './css/style.css';
import Home from './HomeComponent';
import {loadCartInt} from './redux/action/cartAction';
import {int} from './redux/action/userAction';
import Product from './components/Product/Product';
import ProductCategory from './components/Product/ProductCategory';
import ProductSearchList from './components/Product/ProductSearchList';
import SubCaptegory from './components/Category/SubCaptegory';

import CustomerLayout from './layouts/customer.layout'
import Profile from './components/Customer/Profile';
import AddressPage from './components/Customer/Address';
import AddressEditPage from './components/Customer/AddressEdit';
import AddressAddPage from './components/Customer/AddressAdd';
import WishlistPage from './components/Customer/Wishlist';
import MyOrderPage from './components/Customer/MyOrder';
import MyOrderDetail from './components/Customer/MyOrderDetail';
import ChangePasswordPage from './components/Customer/ChangePassword';

import Auth from './components/Auth/Auth';
import Thanks from './components/Checkout/Thanks';
import Registration from './components/Auth/Register';
import ProductList from './components/Product/ProductList';
import CartPage from './components/Cart/index';
import CheckoutPage from './components/Checkout/index';

import Return from './components/Page/Return';
import Shipping from './components/Page/Shipping';
import FAQ from './components/Page/FAQ';
import TrackOrder from './components/Page/TrackOrder';
import Warranty from './components/Page/Warranty';
import RepairMaintenance from './components/Page/RepairMaintenance';
import OurStores from './components/Page/OurStores';
import TermsConditions from './components/Page/TermsConditions';
import PrivacyPolicy from './components/Page/PrivacyPolicy';
import CMS from "./components/Page/CMS";

// Admin Layout

import AdminLayout from './layouts/admin.layout';
import AdminLoginLayout from './layouts/AdminLogin';
import ProtectedAdmin from './protectedadmin.route';
import Category from './components/Admin/Category';

import {Routes,Route} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import cartObj from './utility/cartClass'
import authObj from './utility/authclass'
import Protected from './protected.route';
import { useEffect } from 'react';
function App() {
  const dispatch=useDispatch();
  const cartData=useSelector((state)=> state.cartReducer);  
  const userData=useSelector((state)=> state.userReducer); 
  
  cartObj.setCartData(cartData);
  authObj.setData(userData);

  useEffect(()=>{
    dispatch(loadCartInt());
    dispatch(int());
    
  },[])
 


  return (

    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/product/" element={<ProductList/>}></Route>    
      <Route path="/product/:sku" element={<Product/>}></Route> 
      <Route path="/category/:id" element={<SubCaptegory/>}></Route> 
      <Route path="/productcategory/:id" element={<ProductCategory/>}></Route> 
      <Route path="/search/" element={<ProductSearchList/>}></Route>      
      <Route path="/cart" element={<CartPage/>}></Route>
      <Route path="/checkout" element={<Protected><CheckoutPage/></Protected>}></Route>
      <Route path="/login" element={<Auth/>}></Route>      
      <Route path="/return-policy" element={<Return/>}></Route>
      <Route path="/shipping-delivery" element={<Shipping/>}></Route>
      <Route path="/faqs" element={<FAQ/>}></Route>
      <Route path="/track-order" element={<TrackOrder/>}></Route>
      <Route path="/warranty" element={<Warranty/>}></Route>
      <Route path="/repair-maintenance" element={<RepairMaintenance/>}></Route>
      <Route path="/our-stores" element={<OurStores/>}></Route>
      <Route path="/terms-condition" element={<TermsConditions/>}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
      <Route path="/about-company" element={<CMS url={"about-company"} />}></Route>
      <Route path="/who-we-are" element={<CMS url={"who-we-are"} />}></Route>
      <Route path="/about-designer" element={<CMS url={"about-designer"} />}></Route>
      <Route path="/know-your-rug" element={<CMS url={"know-your-rug"} />}></Route>
      
      <Route path="/registration" element={<Registration/>}></Route>
      <Route path="/thanks" element={<Thanks/>}></Route>
      <Route path ="/customer" element={<Protected><CustomerLayout></CustomerLayout></Protected>}>     
        <Route index element={<Profile />}></Route>
        <Route path="/customer/myorder/:id" element={<MyOrderDetail />}></Route>
        <Route path="/customer/myorder" element={<MyOrderPage />}></Route>
        <Route  path="/customer/address" element={<AddressPage />}></Route>
        <Route  path="/customer/add-address/" element={<AddressAddPage />}></Route>
        <Route  path="/customer/address/:id" element={<AddressEditPage />}></Route>       
        <Route  path="/customer/wishlist" element={<WishlistPage />}></Route>
        <Route  path="/customer/changepassword" element={<ChangePasswordPage />}></Route>
      </Route>

      <Route path ="/admin/login" element={<AdminLoginLayout></AdminLoginLayout>}>  </Route>

     <Route path ="/admin" element={<ProtectedAdmin><AdminLayout></AdminLayout></ProtectedAdmin>}>     
        <Route index element={<Profile />}></Route>
        <Route path="/admin/category" element={<Category />}></Route>
        
       
  </Route>
   </Routes>
    
  );
}

export default App;
