import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css'
import productObj from '../../utility/productClass'
import AdvBanner from "./AdvBanner";
import commonObj from "../../utility/commonclass";

import React, { useState,useEffect } from 'react';

var bgimg2 = require("../../images/banner-png.jpg");

const items=[
    {name:"",
    image:"f1.jpg",
    min:"0",
    max:"599",
    },
    {name:"",
    image:"f2.jpg",
    min:"0",
    max:"599",
    },
    {name:"",
    image:"f3.jpg",
    min:"0",
    max:"599",
    },
    {name:"",
    image:"f4.jpg",
    min:"0",
    max:"599",
    }
]
const PriceFilter = function (props) {
    const [banner,setBanner]=useState({list:[],url:""});
    const [pocketbanner,setPocketbanner]=useState({list:[],url:""});
    const [youandmebanner,setyouandmebanner]=useState({list:[],url:""});
    useEffect(()=>{       
      //  getWhatsNew();
        getPocketBanner();
       // getYouAndMeBanner();

    },[])

    const getWhatsNew=async()=>{
        let bannerData=await commonObj.getBanners({type:"whatsnew"});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    const getPocketBanner=async()=>{
        let bannerData=await commonObj.getBanners({type:"PocketFriendly"});
        console.log("pocket Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setPocketbanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    const getYouAndMeBanner=async()=>{
        let bannerData=await commonObj.getBanners({type:"youandme"});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setyouandmebanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    return (<>
   
   <section className="banner__area pb-50">
            <div className="container">

                <div className="row new-function">
                   
                    <h3 className="cargrv">Pocket Friendly Picks</h3>

                           {pocketbanner?.list?.length > 0 && pocketbanner?.list?.map((item,itemkey)=>{
                               return (
                                <div className="col-lg-3 col-md-6 col-6 price-filter-add">
                                     <Link to={(item?.link !=null)?item?.link:""} key={itemkey}>
                                        
                               <div className="price-filter" key={item?.id} style={{backgroundImage: `url('${pocketbanner?.url}/${item?.image_name}')`}}>
                              Test
                                 {/* <div className="price-description">
                                  <h5>{item?.name}</h5>
                                  <span>{item?.description}</span>
                               </div>*/}
                               
                                
                            </div> </Link>
                            </div>
                            )
                           
                           })}
         
                </div>
                <div className="row new-function">

                    <div className="col-md-12">
                                <div className="bag_image">
                                <div className="nrew_cjcnc"  style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                <div className="ndwcnscw">
                                 <h3><spam>All We Need Is a chance</spam> </h3>
                                
                                 </div>
                                </div>
                                </div>
                             </div>  

                    </div>



                <div className="row new-function">
                   
                    
                            
                    
                    
                        
                        
                                <div className="col-lg-3 col-md-6 you-me-box">
                                <div className="doormant_image">
                                <img className="" src={require("../../images/DSC_8748 DECORE.jpg")} alt=""/>
                            </div>
                             </div>
                             <div className="col-lg-6 col-md-12">
                             
                                <div className="new-boefkeo ">
                                <h3 className="fg">Customise Your Product</h3>
                                <div className="cuton_bore_vdk dfghjklkjhgf">
                                <p className="btn-shine">Customise a Carpet For<br/> Your Home</p>
                                <div className="call_whatsapp">
                                   <a href="https://wa.me/+919935036578" target="_blank"><img style={{  width: '100%', maxWidth: '20px'}} src={require("../../images/whatsapp.png")} /> WhatsApp</a>
                                   
                                   <a href="tel:9935036578"><img style={{  width: '100%', maxWidth: '20px'}} src={require("../../images/call.png")} /> Call Now</a>
                                </div>
                            </div>
                            <div className="cuton_bore_vdk mnbvcx_xcvbnm dfghjklkjhgf">
                                <p className="btn-shine">Customise a Doormat <br/>For Your Home</p>
                                <div className="call_whatsapp">
                                   <a href="https://wa.me/+919935036578" target="_blank"><img style={{  width: '100%', maxWidth: '20px'}} src={require("../../images/whatsapp.png")} /> WhatsApp</a>
                                   
                                   <a href="tel:9935036578"><img style={{  width: '100%', maxWidth: '20px'}} src={require("../../images/call.png")} /> Call Now</a>
                                </div>
                            </div>
                            </div>
                             </div>
                             <div className="col-lg-3 col-md-6 you-me-box">
                                <div className="doormant_image">
                                <img className="" src={require("../../images/DSC_8748 DECORE.jpg")} alt=""/>
                            </div>
                             </div>
                           
                                                                      
                   
                   




                </div>
                {/*<div className="row">
                   
                                                              
                   
                    <div className="col-md-12">                   
                    <Link to={(banner?.list[0]?.link !=null)?banner?.list[0]?.link:""}>
                            <div className="slider__item slider__height swiper-slide d-flex align-items-center include-bg" style={{
                                backgroundImage: `url(${banner?.url+'/'+banner?.list[0]?.image_name})`
                            }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xxl-5">
                                            <div className="slider__content p-relative z-index-11">


                                                <div className="slider__btn" data-animation="fadeInUp" data-delay=".4s">
                                                    <div className="label-box">{banner?.list[0]?.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>




                </div>*/}

            </div>
        </section>
    </>)

}
export default PriceFilter;