import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import productObj from '../../utility/productClass'
import { Empty } from 'antd';
import React, { useState,useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const CaptegoryProduct = function (props) {
    const [tab, setTab] = useState("newtab");
    const [categoryData, setCategoryData] = useState({url:"",list:[]});
    const [filter, setFilter] = useState({categories:[],price:[] });
    const [categoryname,setCategoryName]=useState("")
    const category = 2;
useEffect(()=>{

   async function getData(){
    const data= await productObj.getCategory({parent:category});  
    setCategoryData({list:data.category,url:data.url});    
   }

   getData();
    
},[])

    return (<>
          <Header searchkey={filter.searchkey}></Header>
            <section className="shop-details pt-90 pb-90">

                <div className="container">
                    <div className="row">
                 
                        
                        <div className="col-md-12">
                            <div className="shop-top-area mb-20">
                            
                                <h2>Product Category {categoryname}</h2>
                            </div>
                            
                            <div className="shop-main-area mb-40">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="tab1">
                                        <div className="row">

                                        {categoryData?.list?.length > 0 && categoryData?.list.map((item)=>{
                                            return (<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6" key={item?.id}  title={item?.productName}>
                                                <div className="product__item mb-20">
                                                <Link to={productObj.categoryUrl(item)}>
                                                <div className="product__thumb w-img fix">
                                                {productObj.categoryImage(item,categoryData?.url)}
                                                </div>
                                                </Link>
                                                <div className="product__content">
                                                            <div className="product__content_box">
                                                            <Link to={productObj.categoryUrl(item)}>{item.categoryName}</Link>
                                                                </div></div>
                                                </div>
                                            </div>)
                                        })}


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>
            <Footer></Footer>
    </>)

}

export default CaptegoryProduct;