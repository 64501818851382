import {useSelector,useDispatch} from 'react-redux';
import cartObj from '../../utility/cartClass'
import { Link } from "react-router-dom";
import './HeaderCart.css';
const HeaderCart = function (props) {  
    const qty=cartObj.getCartCount();
    const cartData=cartObj.cartData;
    return (<>
        <div className="shopping-item">
   <Link to="/cart"><i className="fa fa-shopping-cart"></i> <span className="product-count">{qty}</span> </Link>
                {Object.keys(cartData)?.length > 0 && <div className="product-item">
                    {qty > 0 &&
                    <div className="Cart-box">
                     <table className="table">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(cartData).map((item,itemkey)=>{
                                return (<tr key={itemkey}>
                                    <td><img src={cartData[item].product.productImage} alt="Image" /></td>
                                    <td>{cartData[item].product.productName}</td>
                                    <td>{cartData[item].cartQty}</td>
                                    <td>{cartObj.PriceDisplay(cartData[item].product)}</td>
                                    <td>{cartObj.cartItemTotalDisplay(cartData[item])}</td>
                                </tr>)
                            })
                        
                        }
                             
                        </tbody>
                    </table>
                    <div  className="cart-link-box">
                        {cartObj.getCartLink()}
                    </div> 
                    </div>
                    }
                                      
                </div>}
       </div>
    </>)
}
export default HeaderCart;