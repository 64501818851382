import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const FAQ = function () {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details sfsfssds-tytr pt-90 pb-90">
                
                <div className="container">
                
                    <div className="row">
                       
                        <div className="col-md-12">
                        <div className="pad-csa-boder">
                        <h1 className="titektre">Frequently Asked Questions</h1>

                            <h3 className="policty text-center-faq">Shipping and Delivery </h3>
                            
                            <div className="accordion-faq">
                              <h3>What are your shipping charges ?</h3>
                              <p> We offer free shipping on all orders within India.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Is there a minimum order value required  for free shipping ?</h3>
                              <p> Yes. Orders above ₹1000 are eligible for free shipping.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> How long will it take to receive my order?</h3>
                              <p>Orders typically arrive within 5-7 business days from the date of purchase.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> What should I do if my order is delayed?</h3>
                              <p>We apologize for any delays in delivery. Please reach out to our customer support team with your order details, and we'll do our best to assist you and provide updates on your shipment. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> What should I do if my order arrives damaged?</h3>
                              <p>We take utmost care in packaging your order, but if it arrives damaged, please contact us immediately with photos of the damaged package and items. We'll arrange for a replacement or refund as per our return policy. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> Do you deliver to remote areas in India?</h3>
                              <p>Yes, we strive to deliver to all locations within India, including remote areas.However, there may be certain remote areas where delivery could take longer than usual.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Can I track my order?</h3>
                              <p>Yes, once your order is shipped, you will receive a tracking number via email /what's app to track the status of your delivery.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>What should I do if my order hasn't arrived within the expected timeframe?</h3>
                              <p>If your order is delayed, please reach out to our customer support team, and we will assist you in tracking your shipment. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Can I change my shipping address after placing the order?</h3>
                              <p>If your order hasn't been shipped yet, you may be able to update your shipping address. Please contact our customer support team as soon as possible to request changes. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Can I cancel my order after it has been shipped?</h3>
                              <p>Unfortunately, once an order has been shipped, it cannot be canceled. You can return the items as per our return policy once you receive them.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Do you offer international shipping?</h3>
                              <p>Currently, we only ship within India. We are working on expanding our shipping options in the future.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Do you offer express delivery options?</h3>
                              <p>Yes, we offer express delivery for an additional fee. Please contact our customer service for more information. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Can I schedule the delivery for a specific date and time?</h3>
                              <p>Yes, Please contact our customer service for more information. dekobuddy @gmail.com, 9935036578</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Do you provide packaging options for gift orders?</h3>
                              <p>Yes,  Please contact our customer service for more information. dekobuddy @gmail.com, 9935036578</p>
                            </div>



                        </div>


                        </div>

                         <div className="col-md-12">
                        <div className="pad-csa-boder">
                        <h1 className="titektre">Order Cancellation</h1>

                            
                            
                            <div className="accordion-faq">
                              <h3>How can I make a change in my order or cancel my order?</h3>
                              <p>Any change or cancellation is only possible if the information is received before the order gets dispatched. Please get in touch with us for the same at <a href="tel:919935036578">919935036578</a> or <a href="mailto:dekobuddy@gmail.com">dekobuddy@gmail.com</a>.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>When can I cancel my order?</h3>
                              <p> Yes. Orders above ₹1000 are eligible for free shipping.</p>
                            </div>

                            



                        </div>

                        
                        </div>

                        <div className="col-md-12">
                        <div className="pad-csa-boder">
                        <h1 className="titektre">Return and Replacement</h1>

                            
                            
                            <div className="accordion-faq">
                              <h3>What is your return policy?</h3>
                              <p>All items purchased online are eligible for 15 days hassle free return. Note: 15 Days Hassle Free Return is not applicable for custom rugs.</p></div>

                            <div className="accordion-faq">
                              <h3>How do I know if an item is eligible for a return?</h3>
                              <p>An item is eligible for return within 15 days of the receipt date of your order. It must be returned in the same condition in which it was delivered to you.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>After how many days can I return an item?</h3>
                              <p>You can return the item within 15 days of the receipt.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> How long does it take to process the refund?</h3>
                              <p>If the item is damage-free, a refund will be initiated by us within 48 hours after passing through quality checks, it may take 7-8 business days to reflect the amount in your account.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>Where do you send the refund?</h3>
                              <p>Refund is processed through the same payment method which was used to place the order.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>I did not receive an email after initiating a return?</h3>
                              <p>Call us or email us to re-request a copy of the email. We will resend it on your registered email address.<a href="mailto:dekobuddy @gmail.com">dekobuddy @gmail.com</a>/<a href="tel:9935036578">9935036578</a> </p>
                            </div>

                            <div className="accordion-faq">
                              <h3>I am an international customer, does the return policy apply to me?</h3>
                              <p>No, as of now 15 days hassle free return policy is valid for customers in India only. For international customers our Warranty Policy is applicable.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3> What if my rug is damaged, defective in any way, or just not the item I ordered?</h3>
                              <p>Each of our rug goes through strict quality checks before it is dispatched. However, in case the rug is damaged in transit, we request you to share with us the pictures of the damaged part, as soon as you receive the rug, at dekobuddy@gmail.com along with your order ID. After receiving your email we will ensure resolution within 1 working day.</p>
                            </div>

                            <div className="accordion-faq">
                              <h3>How many times can a customer initiate a return?</h3>
                              <p>A customer can initiate return up to twice within a period of six months. After two returns, the customer will be required to bear the freight charges associated with their returns.</p>
                            </div>


                        </div>

                        
                        </div>
                        <div className="col-md-12">
                        <div className="pad-csa-boder">
                        <h1 className="titektre">Payment Related</h1>
                        <h3 className="policty text-center-faq"> Cash On Delivery Not Available.</h3>
                        <div className="accordion-faq">
                              <h3> How can I pay?</h3>
                                <ul>
                           <li>You can pay using your Debit card, Credit card, Cash Wallets or Internet Banking through our secure payment gateway.</li>
                           <li>You can also pay us via PhonePe,Google Pay,Paytm</li>
                           <li>You can also pay through Bank Transfer.</li>
                           </ul>
                            </div>

                            <div className="accordion-faq">
                              <h3>Are the prices, which appear on the website, inclusive of all taxes and duties?</h3>
                              <p>Yes, There is No hidden cost. Listed price is final inclusive of all duties /taxes.</p>
                            </div>

                            



                        </div>

                        
                        </div>
                        

                    </div>
                  
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default FAQ