import config from '../config/index'
import common from '../utility/commonclass';
import { Link } from 'react-router-dom';
import {callListApi} from '../services/customer.server';
import {
    updatePassword as updatepass, getwishlist as wishlist,
    deletewishlist as deletewishService,
    getCustomerAddress, getCustomerAddressDetail,
    saveAddressService,
    updateAddressService,updateDefaultAddressService,
    createOrderService,
    updateOrderPaymentStatus,

} from '../services/customer.server'
import authObj from './authclass';
import { notification } from 'antd';

class Customer {
    static myInstance = null;
    static userData = null;
    static token = null;


    static getInstance() {
        if (Customer.myInstance == null) {
            Customer.myInstance = new Customer();
        }

        return this.myInstance;
    }


    async updatePassword(data) {
        const userData = await authObj.getCurrentUser();
        return updatepass(userData.id, data);
    }

    async getWishlist() {
        const userData = await authObj.getCurrentUser();
        if (userData)
            return wishlist(userData.id);
    }

    async deleteWishlist(id) {
        return await deletewishService(id);

    }
    async getAddress() {
        const userData = await authObj.getCurrentUser();
        if (userData)
            return getCustomerAddress(userData.id);
    }
    async getAddressDetail(id) {
        const userData = await authObj.getCurrentUser();
        return getCustomerAddressDetail(userData.id, id);

    }
    async saveAddress(data) {
        const userData = await authObj.getCurrentUser();
        return saveAddressService({ ...data, customerId: userData.id });

    }
    async updateAddress(data) {
        console.log(data)
        return updateAddressService(data);
    }
    async updateDefaultAddress(data) {
        const userData = await authObj.getCurrentUser();
        return updateDefaultAddressService({...data,customerId:userData.id});
    }

    async getDefaultAddress() {
        const userData = await authObj.getCurrentUser();
        console.log('User data');
        console.log(userData);
        
        let data= getCustomerAddressDetail(userData.id, 0,1);
        return Object.keys(data)==='undefined'?{}:data;
    }

    async createOrder(data) {
        const userData = await authObj.getCurrentUser();        
        return createOrderService({...data,order:{...data.order,customerId:userData.id}});
    }

    async updateCODStatus(data) {
        
        const userData = await authObj.getCurrentUser();        
        return updateOrderPaymentStatus(data);
    }

    async getCustomerOrder(data) {
        const userData = await authObj.getCurrentUser();        
        const OrderData = await callListApi('/order/',{userid:userData.id});  

        return OrderData;
       // return updateOrderPaymentStatus(OrderData);
    }

    async getCustomerOrderDetail(data) {
        const userData = await authObj.getCurrentUser();   
        const id=data.id;     
        console.log(`Order Id ${id}`);
        const OrderData = await callListApi('/order/'+id,{orderid:data.id,userid:userData.id});  

        return OrderData;
       // return updateOrderPaymentStatus(OrderData);
    }

    





}
let Instance = Customer.getInstance();
export default Instance;
