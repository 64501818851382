import Header from '../Header/Header';
import Footer from '../Footer/index';
import cartObj from '../../utility/cartClass'
import productObj from '../../utility/productClass'
import { addToCartAction } from '../../redux/action/cartAction'
import Section2 from '../Home/Section2'
import CategoryProducts3 from '../Home/CategoryProducts3'
import { useDispatch } from 'react-redux';
import { InputNumber, notification, Popconfirm, Empty } from 'antd';
import Summary from './summary'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
const CartPage = (props) => {
    const key = 'updatable';
    const deletekey = 'delete';
    const dispatch = useDispatch();
    const cartData = cartObj.cartData;
    const onchangeHandel = async (qty, item) => {
        const product = cartData[item].product;
        await cartObj.updateCartLocalStorageQty(product, qty);
        dispatch(addToCartAction(product));
        notification.success({ key, message: 'Update Cart Successfully' })

    }

    const deleteCartItem = async (item)=>{
        const product = cartData[item].product;
        await cartObj.deleteCartLocalStorage(product);
        dispatch(addToCartAction(product));
        notification.success({ deletekey, message: 'Item has been  removed Successfully' })
    }
    useEffect(()=>{

        window.scrollTo(0, 0);
    },[])
    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                {Object.keys(cartData).length==0 && <Empty description="Cart is Empty"></Empty> }
                { Object.keys(cartData).length > 0 && <div className="row">
                    <div className="col-md-9 col-12">
                        <div className="product-content-right product_rigrt_box">
                            <div className="woocommerce">
                                <form method="post" action="#">
                                {
                                  Object.keys(cartData).map((item, itemkey) => {
                                   return (
                                    <div className="row box-product-detail">
                                       <div className="col-md-3 col-5">
                                         <div className="product-add-in-box images-box">
                                         {cartObj.getProductImage(cartData[item])}
                                         
                                         </div>
                                       </div>
                                       <div className="col-md-5 col-sm-3 col-7">
                                          <div className="product-add-in-box tital-disction">
                                          {productObj.getProductName(cartData[item].product, true)}
                                         {cartObj.getProductVariant(cartData[item])}<br/>
                                         <p>Price: <span className="amount"> {productObj.PriceDisplay(cartData[item].product)}</span></p>
                                         </div>
                                       </div>
                                       <div className="col-md-2 col-3">
                                          <div className="product-add-in-box amout-box-add">
                                          <InputNumber min={1} max={10} className="input-text qty text" defaultValue={cartData[item].cartQty} onChange={(e) => onchangeHandel(e, item)} />
                                          

                                         </div>
                                       </div>
                                       <div className="col-md-1 col-7">
                                          <div className="product-add-in-box amout-box-add-text">
                                         
                                          <span className="amount">{cartObj.cartItemTotalDisplay(cartData[item])}</span>

                                         </div>
                                       </div>
                                       <div className="col-md-1 col-2">
                                          <div className="product-add-in-box cutt">
                                          <Popconfirm title="Do you  want to Delete？" onConfirm={(e)=>deleteCartItem(item)}>
                                           <Link to="" >×</Link>
                                          </Popconfirm>
                                         </div>
                                       </div>
                                       
                                    </div>
                                    )
                                                })
                                            }
                                </form>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">

                        <Summary></Summary>


                     <div className="row sconccc-scccc">
                     <div className="col-md-1 col-1 dfghj-dfdce_erghgf">
                     <img  src={require("../../images/shield.png")} />
                     </div>
                     <div className="col-md-10 col-11">
                     <p>Safe and Secure Payments. Easy returns. 100% Authentic products.</p>
                     </div>
                     </div>

                    </div>
                </div>}
            </div>
        </div>
        
        <Section2></Section2>
        <Footer></Footer>
    </>)

}

export default CartPage;