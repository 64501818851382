import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import commonObj from "../../utility/commonclass";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const HomeSlider = function (props) {

    const [banner,setBanner]=useState({list:[],url:""});
    useEffect(()=>{
    getBenners();

    },[])

    const getBenners=async()=>{
        let bannerData=await commonObj.getBanners({type:'HomeSlider'});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    return (<>

        <section className="slider__area topbg">
            <div className="slider__active swiper-container">
                <div className="container">
                <div className="swiper-wrapper">                
                    {banner?.list?.length >
                     0 &&  
                     <OwlCarousel navContainerClass="main-slider-pagination dfgfdfdfdserr" dots={false} autoplay={true} className='owl-theme product-carousel coltopused' id="bxslider-home4" loop nav items={1}>
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" > 
                             <div className="col-md-12">
                               <p><a href="#" target="_blank" title="Call Now"> Free Shipping</a></p>
                            </div>                    
                        </div>
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" > 
                             <div className="col-md-12">
                               <p><a href="#" target="_blank" title="Call Now"> Warranty Policy</a></p>
                            </div>                    
                        </div>
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" > 
                             <div className="col-md-12">
                               <p>Need Assistance: <a href="tel:+919876543210" target="_blank" title="Call Now"> +919876543210</a></p>
                            </div>                    
                        </div>
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" > 
                             <div className="col-md-12">
                               <p>Email: <a href="mailto:sales@dekobuddy.com" target="_blank" title="Call Now">  sales@dekobuddy.com</a></p>
                            </div>                    
                        </div>
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" > 
                             <div className="col-md-12">
                               <p><a href="track-order" target="_blank" title="Call Now">Track Your Order</a></p>
                            </div>                    
                        </div>
                    </OwlCarousel>
                }
                </div>
                </div>
            </div>
        </section>
    </>)

}
export default HomeSlider;