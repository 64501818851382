import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import commonObj from "../../utility/commonclass";
const AdvBanner = function (props) {
    const [banner,setBanner]=useState({list:[],url:""});
    useEffect(()=>{
        getBenners();

    },[])

    const getBenners=async()=>{
        let bannerData=await commonObj.getBanners({type:props?.type});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    } 
    return (<>
        <section className="product__area categaert-nre-anim1">
            <div className="container">
                <div className="row">
                {banner?.list?.length >0 && <div className="col-md-12"><Link to={(banner?.list[0]?.link !=null)?banner?.list[0]?.link:""}><img src={banner?.url+'/'+banner?.list[0]?.image_name}></img></Link></div>}
                </div>
            </div>
        </section>
    </>)

}

export default AdvBanner;