import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const Return = function () {


    useEffect(() => {
        window.scrollTo(0, 0)


    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        <h1 className="section-title">Return Policy</h1>
                <div className="container">
                    <div className="row">
                    <div className="col-md-2"></div>
                        <div className="col-md-10">

                            <h3 >
                                The Easy Return Process
                                    </h3>
                            <ul class="bullet-ul"><li>Return request must be raised within 07 days, after receipt or order</li><li>Call us on xxxx&nbsp;or email at&nbsp;<a href="#">xyz@</a></li><li>We will arrange a free pick up from your place at a mutually decided time.</li><li>Whenever you choose to self-ship the item, dispatch it within 48 hours of raising the return request.</li><li>On receipt of the returned item, it goes through a quality check. If the item is damage-free, we will initiate the refund process within 48 hours.</li></ul>
                           

                        </div>

                    </div>
        <br/>
                    <div className="row">
                    <div className="col-md-2"></div>
                        <div className="col-md-10">

                        <h3 >Terms & Conditions</h3>
                            <ul class="bullet-ul"><li>Return request must be raised within 07 days, after receipt or order</li><li>Call us on xxxx&nbsp;or email at&nbsp;<a href="#">xyz@</a></li><li>We will arrange a free pick up from your place at a mutually decided time.</li><li>Whenever you choose to self-ship the item, dispatch it within 48 hours of raising the return request.</li><li>On receipt of the returned item, it goes through a quality check. If the item is damage-free, we will initiate the refund process within 48 hours.</li></ul>
                           

                        </div>

                    </div>

                   


                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default Return