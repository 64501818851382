
import productObj from '../../utility/productClass'
import customerObj from '../../utility/customerClass'
import { useState, useEffect } from 'react';
import { Empty, notification, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import common from '../../utility/commonclass';
const MyOrder = () => {

    const [OrderListData, setOrderList] = useState({ totalrecord: 0, list: [] });
    const [paging, recored] = useState({ currentpage: 1, itemlimit: 10 });
    useEffect(() => {
        async function getData() {
            const orderData = await customerObj.getCustomerOrder({});
            setOrderList({ totalrecord: orderData.count, list: orderData.rows })
        }
        getData();

    }, []);

    return (<>
        <div className="row">
            <div className="col-md-12">
                <div className="product-content-right">
                    <div className="woocommerce">
                        <form method="post" action="#">
                            <table cellspacing="0" className="shop_table cart">
                                <thead>
                                    <tr>
                                        <th className="product-remove">&nbsp;</th>
                                        <th className="customer-name">Order Id</th>
                                        <th>Customer Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Order Date</th>
                                        <th>Order Status</th>
                                        <th>Payment Status</th>
                                        <th>Order Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        OrderListData.list.map((item, itemkey) => {
                                            return (
                                                <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemkey + 1)}</td>
                                                <td ><Link to={`/customer/myorder/${item.id}`} >{item.id}</Link></td>
                                                <td >{item.customer['firstName']  +' '+  item.customer['lastName']}</td>
                                                <td >{item.email}</td>
                                                <td >{item.mobile}</td>
                                                <td >{common.dateFormat(item.orderDate)}</td>
                                                <td >{item.orderStatus}</td>
                                                <td > {(item.paymentStatus == '1') ? 'Success' : 'Pending'}
                                                     </td>
                                                <td >{(item.currency)+' '+(item.totalPrice)}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                    {OrderListData.list.length == 0 && <tr><td colSpan="7"><Empty description="There is no order"></Empty></td></tr>}

                                </tbody>
                            </table>
                        </form>


                    </div>
                </div>
            </div>
            <div className="col-md-3">




            </div>
        </div>
    </>)

}
export default MyOrder;