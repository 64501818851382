import { Link } from "react-router-dom";
import config from '../../config/index'
const Footer =function(props){

return (
    <>
 <footer>
         <div className="footer__area footer-bg">
            <div className="footer__top footer__top-space">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3">
                        <div className="footer__widget footer__widget-border footer-col-1 pb-30 righ-bor">
                           <div className="footer__info">
                            
                              <div className="">

                              <h3 className="footer__widget-title">Reach us @ Dekobuddy</h3>
                              <div className="footer__widget-content">
                              <div className="footer__contact">
                                 <p><i class="fa fa-phone" aria-hidden="true"></i> <Link to={`tel:${config.mobile}`}>{config.mobile}</Link>
</p>
<p><i class="fa fa-whatsapp" aria-hidden="true"></i> <Link to={`tel:${config.mobile}`}>{config.mobile}</Link>
</p>
<p><i class="fa fa-instagram" aria-hidden="true"></i> <a href={config.instagram}>{config.instagram}</a>
</p>
<p><i class="fa fa-envelope-o" aria-hidden="true"></i> <Link to={`mailto:${config.email}`}>{config.email}</Link>
</p>
<br/>
                                
                              </div>
                           </div>
                           
                            
                              
                               
                              </div>
                           </div>

                        </div>

                     </div>
                     <div className="col-md-3">
                        <div className="footer__widget footer__widget-border footer-col-2 pb-30 righ-bor">
                           <h3 className="footer__widget-title">About Dekhobuddy</h3>
                           <div className="footer__widget-content">
                              <div className="footer__contact">
                              <div className="footer__links">
                                 <ul>
                                    <li>
                                       <Link to="/who-we-are">Who  Are We</Link>
                                    </li>
                                   {/* <li>
                                       <Link to="/about-company">The Company </Link>
                                    </li>
                                    <li>
                                       <Link to="/about-designer">The Designer</Link>
                                    </li>*/}
                                 </ul>
                              </div>
                              
                                
                              </div>
                           </div>
                          
                        </div>
                     </div>

                     <div className="col-md-3">
                        <div className="footer__widget footer__widget-border footer-col-3 pb-30 righ-bor">
                           <h3 className="footer__widget-title">Guide & Policy</h3>
                           <div className="footer__widget-content">
                              <div className="footer__links">
                                 <ul>
                                    <li>
                                       <Link to="/faqs">Frequently Asked Questions</Link>
                                    </li>
                                     <li>
                                       <Link to="/shipping-delivery">Shipping & Return Policy  </Link>
                                    </li>
                                    
                                    <li>
                                       <Link to="/terms-condition"> Terms & Conditions</Link>
                                    </li>

                                   
                                 </ul>
                              </div>
                           </div>
                           

                          
                          
                        </div>
                     </div>
                  
                     <div className="col-md-3">
                        <div className="footer__widget footer-col-5 pb-30">
                           <h3 className="footer__widget-title">Our Services</h3>
                           <div className="footer__widget-content">
                              <div className="footer__links">
                                 <ul>
                                    <li>
                                       <Link to="/warranty">Warranty Policy</Link>
                                    </li>
                                    
                                    <li>
                                       <Link to="/track-order">Track Order</Link>
                                    </li>  
                                    <li>
                                       <Link to="/repair-maintenance">Repair & Maintenance</Link>
                                    </li>                                 
                                 </ul>
                              </div>
                           </div>
                           
                          
                           
                        </div>

                     </div>
                     

                     
                      <div className="col-md-4">
                        <div className="footer__widget footer__widget-border footer-col-1 pb-30">
                           <div className="footer__info">
                            
                              <div className="">

                              <h3 className="footer__widget-title">Follow Us</h3>
                           <div className="footer__social">
                                    <ul>
                                       
                                       <li>
                                          <a href="https://www.instagram.com/dekobuddy?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                                          <i className="fa fa-instagram"></i>
                                          </a>
                                       </li>
                                       
                                    </ul>
                                 </div>
                            
                              
                               
                              </div>
                           </div>

                        </div>

                     </div>
                     <div className="col-md-4">
                        <div className="footer__widget footer__widget-border footer-col-1 pb-30">
                           <div className="footer__info">
                           <div className="">
                              <h3 className="footer__widget-title">Newsletter</h3>
                                 <form action="#">
                                    <div className="footer__subscribe-input">
                                       <input type="email" placeholder="Enter Email ID ..."/>
                                       <button type="submit"><i className="fa fa-telegram"></i></button>
                                    </div>
                                 </form>
                                 
                               
                              </div>
                           </div>

                        </div>


                     </div>
                     <div className="col-md-4">
                        <div className="footer__widget footer__widget-border footer-col-1 pb-30">
                           <div className="footer__info">
                           <div className="">
                              <h3 className="footer__widget-title">Accepted</h3>
                                 <img className="" src={require("../../images/payment-method_69e7ec.png")} alt=""/>
                                 
                               
                              </div>
                           </div>

                        </div>


                     </div>
                   
                  </div>

               </div>

            </div>
         
            <div className="footer__bottom">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="footer__bottom-wrapper">
                           <div className="footer__bottom-links">
                              <ul>
                                
                                 <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                 </li>
                                 <li>
                                    <Link to="/terms-condition">Terms & Conditions</Link>
                                 </li>
                                
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="footer__bottom-wrapper">
                           <div className="footer__copyright">
<p>{config.copyright} {"Developed and Marketed by: "}<Link to="https://www.shadowinfosystem.com/">Shadow infosystem (P) Limited</Link></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>

         </div>

      <div id='whatsapp-chat' class='hide'>
  <div class='header-chat'>
    <div class='head-home'>
      <div class='info-avatar'>
      <img
                        src={require("../../images/dekho_right_logo.png")}
                        className="county-logo"
                        alt="dekobuddy"
                      /></div>
      <p><span class="whatsapp-name">Dekobuddy Team</span><br/><small>We Will Respond You Soon</small></p>

    </div>
    <div class='get-new hide'>
      <div id='get-label'></div>
      <div id='get-nama'></div>
    </div>
  </div>
  <div class='home-chat'>

  </div>
  <div class='start-chat'>
    <div pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png" class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body">
      <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
        <div style={{opacity: 0}} class="WhatsappDots__Component-pks5bf-0 eJJEeC">
          <div class="WhatsappDots__ComponentInner-pks5bf-1 hFENyl">
            <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 ixsrax"></div>
            <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 dRvxoz"></div>
            <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 kXBtNt"></div>
          </div>
        </div>
        <div style={{opacity: 1}} class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq">
          <div class="WhatsappChat__Author-sc-1wqac52-3 bMIBDo">Dekobuddy Team</div>
          <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">Hi there 👋<br/><br/>How can I help you?</div>
          
        </div>
      </div>
    </div>

    <div class='blanter-msg'>
      <textarea id='chat-input' placeholder='Write a message' maxlength='120' row='1'></textarea>
      <a href='javascript:void;' id='send-it'><svg viewBox="0 0 448 448"><path d="M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z"/></svg></a>

    </div>
  </div>
  <div id='get-number'></div><a class='close-chat' href='javascript:void'>×</a>
</div>
<a class='blantershow-chat' href='javascript:void' title='Show Chat'>
<p>How Can I Help You!</p><img src={require("./../../images/whatsapp.png")} className="county-logo" alt="Dekobuddy"/> </a>
<a class="fixed-tel" href="tel:+919876543210" target="_blank" title="Call Now"><img src={require("./../../images/phone-call (1).png")} className="county-logo call-burrow" alt="Dekobuddy"/></a>
      </footer>
      
</>


)

}
export default Footer;