import { Dropdown, Menu, message } from 'antd';
import React from 'react';
import {  UserOutlined } from '@ant-design/icons';
export default function Header(){

  

  const handleButtonClick = (e) => {
    message.info('Click on left button.');
    console.log('click left button', e);
  };
  
  const handleMenuClick = (e) => {
    message.info('Click on menu item.');
    console.log('click', e);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Profile',
          key: '1',
          icon: <UserOutlined />,
        },        
        {
          label: 'Logout',
          key: '2',
          icon: <UserOutlined />,
        },
      ]}
    />
  );

return (
    <header className="header header-sticky mb-4">
    <div className="container-fluid">
      <button className="header-toggler px-md-0 me-md-3" type="button">
        <svg className="icon icon-lg">
         
        </svg>
      </button><a className="header-brand d-md-none" href={()=>false}>
        <svg width="118" height="46" alt="CoreUI Logo">
         
        </svg></a>
     
      <ul className="header-nav ms-auto">
        <li className="nav-item"><a className="nav-link" href={()=>false}>
            <svg className="icon icon-lg">
              
            </svg></a></li>
        <li className="nav-item"><a className="nav-link" href={()=>false}>
            <svg className="icon icon-lg">
              
            </svg></a></li>
        <li className="nav-item"><a className="nav-link" href={()=>false}>
            <svg className="icon icon-lg">
             
            </svg></a></li>
      </ul>
      <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
     Ravi Solanki
    </Dropdown.Button>
      
    </div>
    <div className="header-divider"></div>
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-0 ms-2">
          <li className="breadcrumb-item">
          <span>Home</span>
          </li>
          <li className="breadcrumb-item active"><span>Dashboard</span></li>
        </ol>
      </nav>
    </div>
  </header>

)

}