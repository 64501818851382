import { InputNumber, notification, Divider } from 'antd';
import React, { useState, useEffect } from 'react';
const Variant = (props) => {
    const variant = props.variantData;
    const selectvariant = props.selectvariant;
    console.log("variant",variant);



    return (<>
        <div className="attribute-box">
            <label>{variant?.detail?.name}</label>
            <ul className="attribute-ul">
                {
                    Object.keys(variant.options).map((item, itemKey) => {
                        let variantliclass = "";
                        if (selectvariant[variant?.detail?.id] && selectvariant[variant?.detail?.id] == variant.options[item]?.id) {
                            variantliclass = "active";
                        }
                        return (<li key={itemKey} onClick={(e) => props.clickVariant(variant.options[item], variant?.detail?.id)} className={variantliclass}>{variant.options[item].optionName}</li>
                        )
                    })
                }

            </ul>

        </div>
        <Divider />
    </>)

}

export default Variant;