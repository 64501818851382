
import { Slider,Checkbox,InputNumber,Skeleton,Space } from 'antd';
import React,{ useState,useEffect,memo } from 'react';
import productObj from '../../utility/productClass'
import { Link } from 'react-router-dom';
const FilterComponent = (props) => {
    const categorybox=props?.categorybox==true?true:false;
const [categorys,setCategory]=useState([]);
const [conditions,setConditons]=useState([]);
const [manufacturer,setManufacturer]=useState([]);
const [selectedcat,setSelectedCat]=useState([]);
const [selectedconditions,setSelectedConditions]=useState([]);
const [selectedmanufacturer,setSelectedManufacturer]=useState([]);
const [selectedPrice,setSelectedPrice]=useState({min:0,max:100000});
const [loader,setloader]=useState(false);



useEffect(()=>{

  //setSelectedCat((pre)=>([...pre,...props['categories']]));  
   async function getData(){
    setloader(true);
        const catData= await productObj.getCatgorywithCount({});
        console.log(catData);
        setCategory(catData);
        setloader(false);
    }

    getData();
   // getConditions();
    //getManufacturer();

},[]);

const getConditions=async()=>{
    const conditionsData= await productObj.getConditions({});
    console.log("conditionsData");
    console.log(conditionsData);
    setConditons(conditionsData);
}

const getManufacturer=async()=>{
    const conditionsData= await productObj.getManufacturer({});
 
    setManufacturer(conditionsData);
}


const onChange=async (e)=>{  
    const tmp=[...selectedcat];  
   
    if(e.target.checked===true){
        if(tmp.indexOf(e.target.value)===-1){
            tmp.push(e.target.value);
        }
         

    }else{
        const index = tmp.indexOf(e.target.value);
        if (index > -1) {
            tmp.splice(index, 1);
        }
    }
    let unique = [...new Set(tmp)];    
    console.log(unique);
    await setSelectedCat(unique);
    
    props.FilterProduct({categories:unique,price:selectedPrice,condition:selectedconditions,manufacturer:selectedmanufacturer});
  

}

const onChangeConditions=async (e)=>{  
    const tmp=[...selectedconditions];  
   
    if(e.target.checked===true){
        if(tmp.indexOf(e.target.value)===-1){
            tmp.push(e.target.value);
        }
         

    }else{
        const index = tmp.indexOf(e.target.value);
        if (index > -1) {
            tmp.splice(index, 1);
        }
    }
    let unique = [...new Set(tmp)];    
  
    await setSelectedConditions(unique);
    props.FilterProduct({categories:selectedcat,price:selectedPrice,condition:unique,manufacturer:selectedmanufacturer});

}


const onChangeManufacturer=async (e)=>{  
    const tmp=[...selectedmanufacturer];  
   
    if(e.target.checked===true){
        if(tmp.indexOf(e.target.value)===-1){
            tmp.push(e.target.value);
        }
         

    }else{
        const index = tmp.indexOf(e.target.value);
        if (index > -1) {
            tmp.splice(index, 1);
        }
    }
    let unique = [...new Set(tmp)];    
  
    await setSelectedManufacturer(unique);
    props.FilterProduct({categories:selectedcat,price:selectedPrice,condition:selectedconditions,manufacturer:unique});

}

const checkSelected =(id)=>{
   
    return (selectedcat.indexOf(id) > -1)    

}

const checkConditionsSelected =(id)=>{
   
    return (selectedconditions.indexOf(id) > -1)    

}


const checkmanufacturerSelected =(id)=>{
   
    return (selectedmanufacturer.indexOf(id) > -1)    

}

const changePrice =(e,type)=>{
    console.log(e);
    console.log(type);
    let tmp={...selectedPrice};
    tmp[type]=e.target.value;
    setSelectedPrice(tmp);
  //  props.FilterProduct({categories:tmp,price:tmp});
}





    return (
    
    <>
        <div className="pproduct-sidebar-area">
           {categorybox==true && <div className="product-widget mb-30">
                <h5 className="pt-title mb-20">Product categories</h5>
                <div className="widget-category-list">
                    <form action="#">
                        {loader==true && <>
                        
                        <Skeleton.Input active={true} size="small"  style={{marginBottom:5,width:'100%'}}/>
                     
                       <Skeleton.Input active={true} size="small"  style={{marginBottom:5,width:'100%'}}/>                       
                     
                       <Skeleton.Input active={true} size="small"   style={{marginBottom:5,width:'100%'}}/> 
                     </>}
                        {
                            categorys.map((item)=>{

                            return (<p key={item.id}><Checkbox  checked={checkSelected(item.id)} onChange={onChange} value={item.id}>{item.categoryName} ({item.ProductCount})</Checkbox></p>)
                            })
                        }
                    </form>
                </div>
            </div> }
            <div className="product-widget mb-30">
                <div className="single-widget">
                    <h5 className="pt-title pb-20">Filter By Price</h5>
                    <form action="#">
                        <div className="ui-price-slider">
                            <div className="slider-range">
                            <div className="row">
                            <div className="col-12">
                           
                            </div>
                            </div>
                               <div className="filter_products_box align-items-center justify-content-between flex-gap new-filtter-price" >
                                    <div className="min_value_box">
                                             Min: <span><InputNumber min={0} max={10000} defaultValue={0} name="min" onBlur={(e)=>{changePrice(e,'min')}} /></span>
                                       
                                    </div>

                                    <div className="max_value_box">
                                    Max: <span><InputNumber min={0} max={100000} defaultValue={100000} name="max" onBlur={(e)=>{changePrice(e,'max')}} /></span>
                                    </div>

                                    <div className="go_button"><a  className="btn btn-sm price-filter-btn nre-function-price-button" onClick={(e)=>{
                                        props.FilterProduct({categories:selectedcat,price:selectedPrice});
                                    }}>Modify</a></div>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          
           
        </div>

    </>)

}

export default memo(FilterComponent);