import { Button, Form, Input, Switch } from 'antd';
import { useState, useEffect } from 'react';
import CustomerObj from '../../utility/customerClass';
import { useNavigate , Link} from 'react-router-dom';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


const PAYPAL = (props) =>{
    console.log(props);
    const navigate= useNavigate()
  const order=props.order;
  console.log('payment');
  console.log(order);
let [captchaurl,setCaptchaUrl]=useState("");
let [captchaNumber,setCaptchaNumber]=useState("");


const createCaptcha=()=> {
    let code="";
  //clear the contents of captcha div first 
  //document.getElementById('captcha').innerHTML = "";
  var charsArray =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@";
  var lengthOtp = 6;
  var captcha = [];
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  var canv = document.createElement("canvas");
  canv.id = "captcha";
  canv.width = 100;
  canv.height = 50;
  var ctx = canv.getContext("2d");
  ctx.font = "25px Georgia";
  ctx.strokeText(captcha.join(""), 0, 30);
  //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  code = captcha.join("");
  setCaptchaNumber(code);

   setCaptchaUrl(canv.toDataURL());
  //document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
}
/*function validateCaptcha() {
  event.preventDefault();
  debugger
  if (document.getElementById("cpatchaTextBox").value == code) {
    alert("Valid Captcha")
  }else{
    alert("Invalid Captcha. try Again");
    createCaptcha();
  }
}*/

useEffect(() => {
  createCaptcha();

}, [])

const updatepayment =async()=>{
  console.log('update payment');

 const res=await CustomerObj.updateCODStatus({"orderid" : order.id, "orderstatus" : 'placed',"paymentId":order.paymentId});
 console.log(res);
 navigate("/thanks");

}

const onFinish = async (values) => {
  if(values.captcha===captchaNumber){
    updatepayment();

  }else{

    createCaptcha();
  }   

};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
    return (<>
      <PayPalScriptProvider options={{ "client-id": "test" }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "1.99",
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        alert(`Transaction completed by ${name}`);
                    });
                }}
            />
        </PayPalScriptProvider>
        <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
            <img src={captchaurl}  alt=""/>
                <Form.Item
                   name="captcha"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                  <Input className={"form-control"} placeholder="Captcha" />
                 

                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit">
                  Place Order
                </Button>
                </Form.Item>

            </Form>
            </>

    )

}
 export default PAYPAL;