import config from '../config/index'
import common from '../utility/commonclass';
import { useParams, Link } from 'react-router-dom';
import commonService from '../services/common.service'
class Cart{
    
    static myInstance = null;
    curreny='₹';
    cartData={}
    constructor(){     
       
    }
    static getInstance() {
        if (Cart.myInstance == null) {
            Cart.myInstance = new Cart();
        }

        return this.myInstance;
    }
    setCartData(data){
        this.cartData=data;
    }
    getCartCount(){

        return Object.keys(this.cartData).length;
    }

    cartTotal(){
        
        let cartAmount=Object.keys(this.cartData).reduce((pre,current)=>{          
                let product=this.cartData[current].product;
                let price=(product.productPrice && !isNaN(product.productPrice))?product.productPrice:0;
                let qty=(this.cartData[current].cartQty)?this.cartData[current].cartQty:1;
                console.log(price+'--'+qty)
             pre += parseFloat(price)*parseFloat(qty);
            return pre;
        },0);
        return isNaN(cartAmount)?0:cartAmount;

    }

    loadCart(state,action){

        return this.AddtoCart(state,action);

    }

    AddtoCart(state,action){
        try{
          const cartData = this.getCartLocalStorage();
            return {...cartData}

        }catch(e){
            console.warn(e);
            return {...state}
        }
        
        return {...state}
    }


    getCartLocalStorage(){       
        try {
            const serialisedState = localStorage.getItem(config.cartKey);
            if (serialisedState === null) return {};
            return JSON.parse(serialisedState);
          } catch (e) {
            console.warn(e);
            return {};
          }

    }

    saveCartLocalStorage(product,qty=1){
       

        try{
            const cartData = this.getCartLocalStorage();     
            if(cartData[product.id]){
                 cartData[product.id].cartQty = cartData[product.id].cartQty + qty;
             }else{
                 cartData[product.id] = {product:{...product},cartQty:qty}
             }       
             localStorage.setItem(config.cartKey,JSON.stringify(cartData));
             return true;
        }catch(e){
            console.warn(e);
            return false;
        }
     
    }

    updateCartLocalStorageQty(product,qty=1){       

        try{
            const cartData = this.getCartLocalStorage();     
             if(cartData[product.id]){
                 cartData[product.id].cartQty =  qty;
             }     
             localStorage.setItem(config.cartKey,JSON.stringify(cartData));
             return true;
        }catch(e){
            console.warn(e);
            return false;
        }
     
    }

    deleteCartLocalStorage(product){   

        try{
            const cartData = this.getCartLocalStorage(); 
            delete cartData[product.id];               
             localStorage.setItem(config.cartKey,JSON.stringify(cartData));
             return true;
        }catch(e){
            console.warn(e);
            return false;
        }

    }
    getProductImage(cartItem = {}) {
        if (cartItem.product && cartItem.product.productImage) {
            return <img src={cartItem.product.productImage} alt="" />
        } else {
            return <img src={require("../images/product.png")} alt="" />
        }

    }

    ProductPrice(product){
        return product?.productPrice;
    }
    ProductCrossPrice(product){
        return product?.productCrossPrice;
    }

    PriceDisplay(product){
        return this.curreny+this.ProductPrice(product);
    }

    CrossPriceDisplay(product){
        return this.curreny+this.ProductCrossPrice(product);
    }

    cartItemTotal(cartItem){
        let total=this.ProductPrice(cartItem.product) * cartItem.cartQty;
        //console.log("total",total);
        //return total;
        return +parseFloat(total).toFixed(2);
    }

    cartItemTotalDisplay(cartItem){

        return this.curreny+this.cartItemTotal(cartItem);

    }
    cartGrossTotal(){
      const cartData = this.getCartLocalStorage();
      let total=0;     
      if(cartData){
        total=   Object.keys(cartData).reduce((pre,current)=>{
            pre = pre +  this.cartItemTotal(cartData[current]);
            return pre;
        },0)
      }
      console.log("total",total);
      
      return parseFloat(total).toFixed(2);
      return total;

    }

    cartGrossTotalDisplay(){
            const total =this.cartGrossTotal();
            return (total)?this.curreny+total:"" ;

    }

   async calculateShiping(address={}){
      if(Object.keys(address).length == 0){
          return false;
      }
       console.log('calculateShiping');
       console.log(address);
      // const address=(Object.keys(this.address).length > 0)?this.address:{};
       // const ZipTo= address.pincode;
       const ZipTo= '35233';
       // const CountryCgTo= address?.country?.sortname;
       const CountryCgTo= "US";
        const CountryCgFrom= "US";
        const ZipFrom= "327476";
      console.log(this.cartData);
       
        let Item=   Object.keys(this.cartData).map((item)=>{

            return {
                'Weight':1,
                'wtUnit':'LB',
                'htUnit':'IN',
                'Length':'1',
                'Width':'1',
                'Width':'1',
            }
        })

     /*   $Weight = $value['Weight'];
		$wtUnit = $value['wtUnit'];
		$htUnit = $value['htUnit'];
		$Length = $value['Length'];
		$Width =  $value['Width'];
		$Height = $value['Height'];*/

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'       
      }
     const data= await commonService.callApi('https://eznetcrm.com/erp/admin/api/Rate/fedex.php',{ZipFrom:ZipFrom,ZipTo:ZipTo,CountryCgFrom:CountryCgFrom,CountryCgTo:CountryCgTo,item:Item});

      if(data?.status==1){
        this.ShippingData={amount:data.amount,currency:data.Currency}
      }else{
        this.ShippingData={}
      }
      return this.ShippingData;
         
    }

    shippingTotal(){

        return (this.ShippingData?.amount > 0)?this.ShippingData?.amount:0;

    }
    shippingTotalDisplay(arg=true){
        if(arg===false){
            return '--';
        }
        console.log('shippingTotalDisplay');
        console.log(this.shippingTotal());
        if(!this.shippingTotal()){
            return 'Free Shipping';
        }else{
            return this.curreny+this.shippingTotal() ;
        }
       

    }


    cartTotalDisplay(){
        const total= parseFloat(this.cartGrossTotal()) + parseFloat(this.shippingTotal());
        return this.curreny+total; 

    }

    getCartLink(label='My Cart'){
        return <Link to="/cart" className="btn">{label}</Link>
    }

    getProductVariant(cart){
       
        if(cart.product.isVariant===1){
            return (<ul className="cart-variant-list">
                {
                    cart.product.varintDetail.map((item,itemKey)=>{

                        return ( <li>{`${item.attributeName} : ${item.attributeOptionName}`}</li>)
                    })
                }
               
                </ul>);
        }
        return '';

    }


    getCondition(item){
        if (item?.productrefType == 'main-condition' || item?.productrefType=='alias-condition') {
        if(item?.productSku.includes("certified-refurbished")==true){
            return "Certified Refurbished";
        }else if(item?.productSku.includes("third-party")==true){
            return "Third Party";
        }else if(item?.productSku.includes("used-no-hologram")==true){
            return "Used No Hologram";
        }else if(item?.productSku.includes("new-0-hrs")==true){
            return "New 0 Hrs";
        }else if(item?.productSku.includes("new-bulk")==true){
            return "New Bulk";
        }else if(item?.productSku.includes("new-sealed-spares")==true){
            return "New Sealed Spares";
        }else if(item?.productSku.includes("blank-trays")==true){
            return "Blank Trays";
        }else if(item?.productSku.includes("new-retail")==true){
            return  "New Retail";
        }else if(item?.productSku.includes("new-open-box")==true){
            return  "New Open Box";
        }else if(item?.productSku.includes("hp-renew")==true){
            return  "HP Renew";
        }else if(item?.productSku.includes("no-tray")==true){
            return  "No Tray";
        }else if(item?.productSku.includes("used")==true){
            return "Used";
        }else{
            return "";
        }
    }else{
        return "";
    }
        
        
    }

    displayCondition(item) {
        
        if (item?.productrefType == 'main-condition' || item?.productrefType == 'alias-condition') {
            let condition=this.getCondition(item);
            return (<div className="product__tag product__tag-4">
                <span className="sku-box"> Condition : {condition}</span>
            </div>)
        } else {
            return ""
        }

    }

    displayQuantity(item){
        if (item?.productQuantity  > 0) {
            return ( <div className="qty-box">
            <span className=""> <strong>Qty</strong>   {item?.productQuantity}</span>
            </div>)
        } else {
            return ""
        }
    }


}
let Instance= Cart.getInstance();
export default Instance;