import { AutoComplete, Input,Spin, Button } from 'antd';
import React, { useState } from 'react';
import { Link,useNavigate } from "react-router-dom";
import {getProducts} from '../../services/product.service';
import { SearchOutlined } from '@ant-design/icons';
const searchResult = (data) =>{

    return data.map((item,itemkey)=>{
        return {value:item.productSku,label:(
        <><Link to={"/product/"+item.productSku}>{item.productName}</Link></>
        )}
    })
}
const HeaderSearch = function (props) {
    let navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    
    const handleSearch = (value) => {       
        let filter={searchkey:value};
        setloader(true);
        getProducts({filter:filter}).then((data)=>{
            setloader(false);
            setOptions(value ? searchResult(data.productdata.rows) : []);
        }).catch((err)=>{

        })       
      };

      const handleChange = (value)=>{
        setSearch(value);
      }
    
      const onSelect = (value) => {
        console.log('onSelect', value);
      };

    
      const searchproduct=()=>{
          if(search){
            navigate('/product/?key='+encodeURI(search))
          }
       
      }
    return (<>
        <div className="auto-search">


            {loader === true && <div className="search-loader">
                <Spin />
            </div>}


            <AutoComplete

                style={{
                    width: "100%",
                    
                }}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                onChange={handleChange}
                value={search}
            >
                <Input size="large" placeholder="Search here" />

            </AutoComplete>
           
            <Button type="primary" style={{
                    right: 0,
                    top:0
                    
                }} className="button-search" shape="circle" icon={<SearchOutlined />} size="large" onClick={searchproduct} />
            
        </div>
    </>)
}
export default HeaderSearch;