import Header from '../Header/Header';
import Footer from '../Footer/index';
import OwlCarousel from 'react-owl-carousel';
import productobj from '../../utility/productClass';
import cartobj from '../../utility/cartClass';
import commonobj from '../../utility/commonclass';
import MyImage from '../Common/MyImage';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Skeleton } from 'antd';
import CartButton from './CartButton';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProductDetail, getRelatedProducts, getProductVariant } from '../../services/product.service';
import {    NotificationOutlined,  } from '@ant-design/icons';
import NotifyButton from './NotifyButton';
import parse from "html-react-parser";
import AdvBanner from '../../components/Home/AdvBanner'
import CategoryProducts3 from '../../components/Home/CategoryProducts3'
import SimilarProducts from '../../components/Home/SimilarProducts'
const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        },
        1366: {
            items: 5,

        }
    },
};

const Product = function () {
    const params = useParams()
    console.log(params)
    const sku = params.sku;
    const [product, setProduct] = useState({ info: {}, media: [], url: "", galleryUrl: "" });
    const [productMedia, setproductMedia] = useState([]);
    const [bigMediaIndex, setbigMediaIndex] = useState(0);
    const [relatedproductListData, setRelatedProduct] = useState({ totalrecord: 0, list: [], url: "" });
   const [componentLoader, setcomponentLoader] = useState(0);

    useEffect(() => {
        setcomponentLoader(0);
        async function fetchData() {
            try {
                const data = await getProductDetail(sku);
                console.log("data",data);
                setcomponentLoader(1);

                if (data.product.isVariant === 1  && 1==2) {
                    const variantData = await productobj.getProductVariant(data.product.id);

                    const productdata = { ...data.product };
                    productdata.variantData = variantData.variants;
                    setProduct((pre) => {
                        return { ...pre, info: productdata, url: data.url, galleryUrl: data.galleryUrl }

                    });

                } else {



                    setProduct((pre) => {
                        return { ...pre, info: data.product, url: data.url, galleryUrl: data.galleryUrl }

                    });

                    let media = [];
                    if (data?.product?.productImage) {
                        media.push(data.url + data?.product?.productImage);
                    } else {
                        media.push(data.url + 'product.png');
                    }
                    console.log('Product Media');
                    console.log(commonobj.isEmpty(product?.info?.productMedia));

                    if (commonobj.isEmpty(data?.product?.productMedia) == false) {
                        data?.product?.productMedia.map((item) => {
                            media.push(data.url + item.mediaName);

                        });
                    }

                    console.log(media);
                    setproductMedia(media);


                }





                const relateddata = await getRelatedProducts(data.product.id, data.product.refId);
                if (commonobj.isEmpty(relateddata) == false) {
                    console.log('related data');
                    console.log(relateddata);
                    setRelatedProduct((pre) => {
                        return { ...pre, totalrecord: relateddata?.productdata?.length, list: relateddata?.productdata, url: relateddata?.url };
                    })

                }




            } catch (e) {
                console.log(e.message)
                return false;
            }
        }
       
        fetchData();
        window.scrollTo(0, 0);
    }, [sku]);

    const loadVarintData = (selectedvarint) => {
        console.log(selectedvarint);
        const attribute = Object.keys(selectedvarint).sort(function (a, b) { return a - b });

        const key = [];
        for (const element of attribute) {
            key.push(selectedvarint[element]);
        }
        const newprice = product.info.variantData.varintKey[key.join('-')]['price'];
        //console.log();
        setProduct((pre) => {

            return { ...pre, info: { ...pre.info, productVariantPrice: newprice, selectVarintKey: key.join('-') } };
        })
    }





    return (<>
        <Header></Header>
       <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <div className="product-content-right">
                             <div className="product-breadcroumb">
                                <a href="">Home</a>
                                {
                                    product.info.productName && <><Link to="/product">Product</Link>
                                        <a href="">{product.info && product.info.productName}</a></>
                                }

                            </div>

                            <div className="row">
                                <div className="col-sm-7 styc-nre-ss sdfghjhgf">
                                    <div className="product-images">
                                        <div className="product-gallery">
                                            {componentLoader==1  &&
                                                productMedia.map((item, key) => {
                                                    return <span key={key} onClick={(e) => { setbigMediaIndex(key) }}><MyImage alt={"Image"} helght={100} width={100} caption={"Image"} src={item}  ></MyImage></span>
                                                })
                                            }
                                        </div>
                                        <div className="product-main-img">
                                           {componentLoader==0 && <Skeleton.Image active={true} />} 

                                            {componentLoader==1  && productobj.ShowImage(bigMediaIndex, productMedia)
                                            }
                                            
                                        </div>
                                        
                                        

                                    </div>
                                </div>

                                <div className="col-sm-5">
                                     
                                    {componentLoader==1  &&
                                        product.info.productName && <div className="product-inner">
                                            <div className="alidvd-add"><h2 className="product-name">{productobj.getProductName(product.info)}</h2>
                                               <div class="bg-box">

        <div class="share-btn">
            <span class="text-share-btn">Share</span>
            <ul class="share-items">
                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                <li><a href="#"><i class="fa fa-telegram-plane"></i></a></li>
                <li><a href="#"><i class="fa fa-whatsapp"></i></a></li>
            </ul>
        </div>

    </div>
                                            </div>

                                            
                                          {cartobj.getCondition(product.info) !='' && <div className="condition-box lh-10"><small className="text-capitalize"><strong>Condition  </strong>  {cartobj.getCondition(product.info)}</small></div>}  
                                           
                                            
                                            <div className="list-of-cartgri-add">
                                            <div className="row">
                                            <div className="list-add-catrr col-6 col-sm-6">
                                        <p><b>Size</b></p>
                                        <p>5*8 feet</p>
                                        </div>
                                        <div className="list-add-catrr col-6 col-sm-6">
                                        <p><b>Price</b></p>
                                        <p>₹590/sqft</p>
                                        </div>
                                        <div className="list-add-catrr col-6 col-sm-6">
                                        <p><b>Material</b></p>
                                        <p>Silk</p>
                                        </div>
                                        <div className="list-add-catrr col-6 col-sm-6">
                                        <p><b>Technique</b></p>
                                        <p>Hand Tufting</p>
                                        </div>
                                        </div>
                                            </div>
                                             
                                            {product?.info?.productPrice > 0 && <div className="product-inner-price lh-10">
                                                <ins>{productobj.PriceDisplay(product.info)}</ins>
                                                {commonobj.isEmpty(productobj.ProductCrossPrice(product.info)) == false && <del>{productobj.CrossPriceDisplay(product.info)}</del>}
                                                <div className="spacelpricesx"><h6 className="sdfbvd-skcsi">Special price</h6>
                                                 <p className="">Inclusive of all taxes</p></div>

                                            </div>

                                            }

                                            
                                             {product?.info?.size !=null && product?.info?.size !=undefined && 
                                                 <p className="product-attr-box"><span className="att-label sdfbfvdvm"> Size Chart : 
                                                 <img style={{  width: '100%', maxWidth: '15px'}} src={require("../../images/ruler.png")} />
                                                 <div class="popup-link">
                                                 
                                                 <a href="#popup6">Size Chart</a>

                                                 </div></span><span className="cat-tag sdfghgfdsdfg-sice">{product?.info?.size}</span></p>

                                            }
                                           
                                        {product?.info?.productCategories !=null && product?.info?.productCategories?.length > 0 && (product?.info?.productCategories?.length!=1 || product?.info?.productCategories[0]?.categoryId!="15") &&
                                            <div className="size_guide">
                                            <p>Size Guide:</p>
                                            <a href="#popup7">Living Room</a>
                                            <a href="#popup8">Bedroom</a>
                                            <a href="#popup9">Dining Room</a>
                                            <a href="#popup10">Corridor</a>
                                            <a href="#popup11">Kitchen</a>
                                            </div>
                        }
                                            
                                            {product?.info?.productCategories !=null && product?.info?.productCategories?.length > 0 && <div className="product-inner-category">
                                                <div className="smcks-scji">
                                                
                                                <p className="product-attr-box"><span className="att-label">Category :</span><br/>{product?.info?.productCategories?.map((item,itemkey)=>{
                                                        if(item?.category !=null){
                                                            return (<span key ={item?.category?.id} className="cat-tag"><Link to={productobj.categoryUrl(item?.category)}>{item?.category?.categoryName}</Link></span>)
                                                        }else{
                                                            return (<></>)
                                                        }
                                                        
                                                    })}

                                                 </p>
                                                 
                                                 </div>
                                                 <form action="" className="cart product-attr-box1">
                                                   
                                                   
                                                {product?.info?.productPrice > 0 && product?.info?.productQuantity > 0 && 
                                                    <CartButton loadVarintData={loadVarintData} isqty={true} product={{ ...product?.info, productImage: ((product.info.productImage) ? product.url + product.info.productImage : "") }} isBoth={true}></CartButton>

                                                }
                                                <br/>
                                                {(product?.info?.productPrice == 0 || product?.info?.productQuantity==0) && <NotifyButton classes="select-btn w-100" item={ product?.info}></NotifyButton>}
                                               <br/>
                                               <div className="more-buttonas">
                                              
                                               <a class="mnn-button" rel="nofollow" href="#">Add Wishlist</a>
                                               
                                               </div>
                                            </form>
                                            <div className="free-shipping-add btn-shine">
                                            <img style={{  width: '100%', maxWidth: '50px'}} src={require("../../images/box.png")} />
                                             <h5 className="free-ship-section">Free Shipping All Over India <br/>Estimated Delivery Within 7 Days</h5>
                                            </div>
                                            <div className="sku-box lh-10"><small className="text-capitalize"><strong>Sku:</strong> <a>{productobj.displaySKU(product.info)}</a> </small></div>
                                                 <ul className="share-idocnc">
                                                 <li className="what">
                                                   <Link to="#">
                                                    Share
                                                   </Link>
                                                   </li>
                                                  
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-facebook"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-instagram"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-whatsapp"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-twitter"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-pinterest"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-linkedin"></i>
                                                   </Link>
                                                   </li>
                                                   <li className="what">
                                                   <Link to="#">
                                                    <i className="fa fa-telegram"></i>
                                                   </Link>
                                                   </li>
                                                   
                                                  </ul>
                                                 
                                                </div> 

                                            }
                                            
                                            
                                            
                                        </div>
                                    }
                                   {componentLoader== 0 
                                        && <><Skeleton /><Skeleton /> <Skeleton /> <Skeleton.Input /> <Skeleton.Input /></>
                                    }

                                </div>
                                
                            </div>

                            {relatedproductListData.totalrecord > 0 &&
                                <div className="related-products-wrapper">
                                    <h2 className="related-products-title">Related Products</h2>

                                    <OwlCarousel className='owl-theme related-products-carousel' {...options}>
                                        {relatedproductListData.list.map((relatedItem, relatedKey) => {

                                           return (<div className="product__item-single" key={relatedItem?.id} title={relatedItem.productName}>
                                            <div className="product__item mb-20">
                                                <div className="product__thumb w-img fix">
                                                    <Link to={'/product/'+relatedItem?.productSku}>
                                                        {productobj.productImage(relatedItem, relatedproductListData.url)}
    
                                                    </Link>
                                                    <div className="product__action transition-3">
                                                        <ul>
                                                            <li>
                                                            {relatedItem.productPrice > 0 &&
                                                                <CartButton product={{ ...relatedItem, productImage: ((relatedItem?.productImage) ? relatedItem?.url + relatedItem?.productImage : "") }} classes="select-btn w-100"> <svg viewBox="0 0 22 22">
                                                                    <g>
                                                                        <path d="M18,19H6c-0.5,0-0.92-0.37-0.99-0.86L3.13,5H1C0.45,5,0,4.55,0,4s0.45-1,1-1h3c0.5,0,0.92,0.37,0.99,0.86L6.87,17h10.39
                                                                                    l2.4-8H11c-0.55,0-1-0.45-1-1s0.45-1,1-1h10c0.32,0,0.61,0.15,0.8,0.4c0.19,0.25,0.25,0.58,0.16,0.88l-3,10
                                                                                    C18.83,18.71,18.44,19,18,19z" />
                                                                    </g>
                                                                </svg></CartButton>}
                                                                {relatedItem.productPrice == 0 && <Link className="select-btn w-100 add_to_cart_button" to={`/product/` + relatedItem.productSku}><NotificationOutlined></NotificationOutlined></Link>}
    
                                                            </li>
                                                      
    
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                <div className="product__tag product__tag-4">
                                                        <span className="sku-box"> <strong> SKU </strong>  :  <Link to={`/product/` + relatedItem?.productSku}>{productobj.displaySKU(relatedItem)}</Link></span>
                                                    </div>


                                                <div className="product__content text-center">
                                                    

                                                    {cartobj.displayCondition(relatedItem)}
                                                    
                                                    <h3 className="product__title">
                                                        {productobj.getProductName(relatedItem, true)}
    
                                                    </h3>
                                                    {productobj.displayPriceQuantity(relatedItem)}
                                                    
                                                </div>
                                            </div>
                                        </div>)

                                        })}


                                    </OwlCarousel>
                                </div>
                            }

                        </div>
                        
                    </div>

                    

                    <div class="accordion-container">
                    <h3>Product Details</h3>
  <details open>
    <summary>
      <span class="accordion-title">
       Product Description
      </span>
      <span class="accordion-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 9l6 6l6 -6" />
        </svg></span>
    </summary>
    <div class="accordion-content">
    <div className="product-details pad-csa-boder">
      {product?.info !=undefined && product?.info?.productDescription !=undefined && parse( product?.info?.productDescription)}
      {product?.info && product?.info?.productShortDescription}
      </div>
    </div>
  </details>
  <details>
    <summary>
      <span class="accordion-title">
        Shipping and Returns 
      </span>
      <span class="accordion-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 9l6 6l6 -6" />
        </svg></span>
    </summary>
    <div class="accordion-content">
     <div className="product-details pad-csa-boder">
       <ul>
       <li>We offer Free Delivery on all orders within India.</li>
       <li>Your order will be processed and dispatched within 1 to 2 business days of receiving payment.</li>
       <li>Please allow 5  to 7 days for your order to arrive, depending on your location and courier service availability.</li>
       <li>For any reason if you are not satisfied, you may return your item(s) within 7 days of receiving your order.(Custome Products are not eligible for return)</li>
       <li><b><a href="/shipping-delivery" target="_blank">See more details in Shipping and Returns</a></b></li>
       </ul>
      </div>
    </div>
  </details>
  
  <details>
    <summary>
      <span class="accordion-title">What is the cosmic horizon?</span>
      <span class="accordion-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 9l6 6l6 -6" />
        </svg></span>
    </summary>
    <div class="accordion-content">
    <div className="product-details pad-csa-boder">
      <h6>Rug Care</h6>
       <ul>
       <li>Shedding is a natural property of new rug.</li>
       <li>If thread comes out do not pull the yarn,trim with scissor.</li>
       
       <li><b>Regular Vacuuming( Do Not Brush) :</b> Aim to vacuum at least once a week, or more frequently in high-traffic areas. </li>
       <li><b>Rotate Frequently:</b> Rotate your rug every 3-6 months to distribute wear evenly and prevent uneven fading.</li>
       <li><b>Avoid Direct Sunlight:</b> To Protect your rug's colour.</li>
       <li><b>Rotate Furniture:</b> Avoid placing heavy furniture directly on the rug for extended periods. </li>
       <li><b>Pet Care:</b> If you have pets, trim their nails regularly to prevent snags and tears in the rug fibers. </li>
       <li><b>Air Out:</b> Occasionally, air out your rug by hanging it outdoors in a shaded area. This helps to refresh fibers and eliminate odors.</li>
       
       </ul>
      </div>
      </div>
  </details>
</div>














                </div>

            </div>

        </div>

         <div className="container">
                <div className="row">
                <div className="col-md-12" data-id={'id-2'}><CategoryProducts3 categoryid="2"></CategoryProducts3></div>
                    
                     

                    
                    
                </div>
            </div>

            
            



<div id="popup6" class="popup-container popup-style-6">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Size Conversion Chart</h3>
                                <table width="100%">
                                    <tbody><tr>
                                        <th>Sizes in Feet</th>
                                        <th>Sizes in CM</th>
                                        <th>Sizes in Inches</th>
                                        <th>Sizes in Meter</th>
                                    </tr>
                                    </tbody><tbody>
                                        <tr>
                                            <td>2x3</td>
                                            <td>60x90 </td>
                                            <td>24x36</td>
                                            <td>0.60x0.91</td>
                                        </tr>
                                        <tr>
                                            <td>3x5</td>
                                            <td>90x150</td>
                                            <td>36x60</td>
                                            <td>0.91x1.52</td>
                                        </tr>
                                        <tr>
                                            <td>4x6</td>
                                            <td>120x180</td>
                                            <td>48x72</td>
                                            <td>1.21x1.82</td>
                                        </tr>
                                        <tr>
                                            <td>5x8</td>
                                            <td>150x240</td>
                                            <td>60x96</td>
                                            <td>1.54x2.43</td>
                                        </tr>
                                        <tr>
                                            <td>6x9</td>
                                            <td>180x270</td>
                                            <td>72x108</td>
                                            <td>1.82x2.74</td>
                                        </tr>
                                        <tr>
                                            <td>8x10</td>
                                            <td>240x300</td>
                                            <td>96x120</td>
                                            <td>2.43x3.04</td>
                                        </tr>
                                        <tr>
                                            <td>9x12</td>
                                            <td>270x360</td>
                                            <td>108x144 </td>
                                            <td>2.74x3.65</td>
                                        </tr>
                                        <tr>
                                            <td>10x14</td>
                                            <td>300x420</td>
                                            <td>120x168 </td>
                                            <td>3.04x43.89</td>
                                        </tr>
                                        <tr>
                                            <td>12x15</td>
                                            <td>360x450</td>
                                            <td>144x180</td>
                                            <td>3.65x4.57</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
  </div>
</div>
<div id="popup7" class="popup-container popup-style-6 Living">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Living Room Size Guide</h3>
                                <div className="row">
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/1.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/2.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/3.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/4.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/5.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/6.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Living/7.jpg")} />
                                </div>
                                
                                </div>
                            </div>
  </div>
</div>
<div id="popup8" class="popup-container popup-style-6 Living">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Bedroom Size Guide</h3>
                                <div className="row">
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/1.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/2.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/3.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/4.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/5.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/6.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/7.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Bedroom/8.jpg")} />
                                </div>
                                
                                </div>
                            </div>
  </div>
</div>
<div id="popup9" class="popup-container popup-style-6 Living">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Dining Room Size Guide</h3>
                                <div className="row">
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/1.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/2.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/3.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/4.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/5.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/6.jpg")} />
                                </div>
                                <div className="col-md-4">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Dining/7.jpg")} />
                                </div>
                                </div>
                            </div>
  </div>
</div>
<div id="popup10" class="popup-container popup-style-6 Living">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Corridor Room Size Guide</h3>
                                <div className="row">
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Corridor/1.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Corridor/2.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Corridor/3.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Corridor/4.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Corridor/5.jpg")} />
                                </div>
                                
                                </div>
                            </div>
  </div>
</div>
<div id="popup11" class="popup-container popup-style-6 Living">
  <div class="popup-content">
    <a href="#" class="close">&times;</a>
    <div class="size-chart-details">
                                <h3 class="mt-0 ff-secondary">Kitchen Room Size Guide</h3>
                                <div className="row">
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Kitchen/1.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Kitchen/2.jpg")} />
                                </div>
                                <div className="col-md-6">
                                <img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/size_guird/Kitchen/3.jpg")} />
                                </div>
                                
                                </div>
                            </div>
  </div>
</div>




        

        <Footer></Footer>

    </>)

}
export default React.memo(Product);