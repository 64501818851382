import Header from '../Header/Header';
import Footer from '../Footer/index';

import CartButton from './CartButton';
import { getProducts } from '../../services/product.service';
import { Skeleton, Pagination,Empty } from 'antd';
import cartobj from '../../utility/cartClass';
import React, { useState, useEffect } from 'react';
import productObj from '../../utility/productClass'
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import FilterComponent from './Filter';

const ProductList = function () {

    const [searchParams, setSearchParams] = useSearchParams();
    const [productListData, setProductList] = useState({ totalrecord: 0, list: [], url: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
    const [filter, setFilter] = useState({ searchkey: searchParams.get('key'),categories:[],price:{} });
    const [componentLoader,setcomponentLoader]=useState(0);
    let navigate = useNavigate();
    const QueryCategory=searchParams.get('category');
        console.log(`QueryCategory ${QueryCategory}`);
    useEffect(() => {
       
        if(searchParams.get('category')){
            
            const qCat=parseInt(searchParams.get('category'));
            console.log(`query cat ${qCat}`)
      
            if(qCat > 0){
                const tmp=[];
                tmp.push(qCat);
                let unique = [...new Set(tmp)]; 
                setFilter((pre)=>({...pre,categories:unique}));
            }
           
        }
        window.scrollTo(0, 0);

    },[QueryCategory])



    useEffect(() => {
        console.log('call Product useEffect');
        getProducts({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setcomponentLoader(1);
            setProductList(() => {
                return { totalrecord: list.productdata.count, list: list.productdata.rows, url: list.url };
            })
        });
    }, [paging,filter.searchkey,filter.categories.length,filter.price,QueryCategory]);


    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }

    const FilterProduct=(filterArg)=>{
        console.log('Call Filter Box');
        console.log(filterArg);
        setFilter((pre)=>({...pre,categories:filterArg.categories,price:filterArg?.price}));

    }


    return (
        <>
            <Header searchkey={filter.searchkey}></Header>
            <section className="shop-details pt-90 pb-90">

                <div className="container">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-3 styc-nre-ss sdf">                          
                                    <FilterComponent FilterProduct={FilterProduct} categories={filter.categories}></FilterComponent>                  
                          </div>
                        <div className="col-xxl-9 col-xl-9">
                            <div className="shop-top-area mb-20">
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-2 col-md-3 col-sm-3">
                                        <div className="shop-top-left">
                                            
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">

                                    </div>
                                    <div className="col-xl-4 col-xl-4 col-md-3 col-sm-3">
                                        <div className="text-sm-end">
                                            <div className="select-default">
                                                <select name="short" id="short" className="shorting-select" >
                                                    <option value="">Default sorting</option>
                                                    <option value="">ASC</option>
                                                    <option value="">DEC</option>
                                                </select>
                                                {/*<div className="nice-select shorting-select" tabindex="0"><span className="current">Default sorting</span><ul className="list"><li data-value="" className="option selected focus">Default sorting</li><li data-value="" className="option">ASC</li><li data-value="" className="option">DEC</li></ul></div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-main-area mb-40">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="tab1">
                                        <div className="row">

                                            {
                                                productListData.list.map((item, itemkey) => {
                                                    return (<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                        <div className="product__item mb-20">
                                                            <div className="product__thumb w-img fix">
                                                            <Link to={`/product/` + item.productSku}>
                                                                {
                                                                    productObj.productImage(item, productListData.url,)
                                                                }
                                                                {/*item.productImage && <img src={productListData.url+item.productImage} alt="" />*/}
                                                                </Link>
                                                            </div>
                                                            <div className="product__content">
                                                                <div class="product__tag product__tag-4">
                                                                    <span>{item.productSku}</span>
                                                                </div>
                                                                <h3 class="product__title">
                                                                    <Link to={`/product/` + item.productSku}>{item.productName}</Link>
                                                                </h3>
                                                                <div class="product__price product__price-4 mb-10">
                                                                    <span class="price"><ins>{cartobj.PriceDisplay(item)}</ins></span> <del>{cartobj.CrossPriceDisplay(item)}</del>
                                                                </div>
                                                                <div class="product__select-button">
                                                                      {/*<div class="svds_product">
                                                                         <img style={{  width: '100%', maxWidth: '30px'}} src={require("../../images/e-commerce.png")} />
                                                                      </div>*/}
                                                                     <CartButton product={{ ...item, productImage: productListData.url + item.productImage }} classes="select-btn w-100 "></CartButton>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>)

                                                })

                                            }

                                            {productListData.list.length == 0 && componentLoader!=0 && <Empty/>}

                                            { componentLoader==0 && <Skeleton />}



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-pagination text-center">
                                <nav>
                                    <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={productListData.totalrecord} onChange={handlePageChange} />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default ProductList