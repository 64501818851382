import Header from '../Header/Header';
import Footer from '../Footer/index';

import CartButton from './CartButton';
import { getProducts } from '../../services/product.service';
import customerObj from '../../utility/customerClass'
import { Skeleton, Pagination,Empty, notification } from 'antd';
import cartobj from '../../utility/cartClass';
import React, { useState, useEffect } from 'react';
import productObj from '../../utility/productClass'
import commonObj from '../../utility/commonclass'
import authObj from '../../utility/authclass';
import { useNavigate, Link, useSearchParams,useParams } from "react-router-dom";
import FilterComponent from './Filter';
import WishList from './Wishlist';
import NotifyButton from './NotifyButton';
const ProductList = function () {
    let param =useParams();
    console.log("param",param['id']);
    const [searchParams, setSearchParams] = useSearchParams();    
    const [filtersort, selectedsort] = useState({orderby:'productName',type:'ASC'});  
    const [productListData, setProductList] = useState({ totalrecord: 0, list: [], url: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 18 });
    const [filter, setFilter] = useState({categories:[],price:[] });
    const [componentLoader,setcomponentLoader]=useState(0);
    const [categoryname,setCategoryName]=useState([])
  
    let navigate = useNavigate();
    console.log(searchParams);
    const QueryCategory=param['id'];
    console.log("QueryCategory",QueryCategory);
   
    useEffect(()=>{
        async function  getCategoryData(){
            const data =await productObj.getCategoryName({id:QueryCategory});
            console.log(data);
            setCategoryName(data.categoryName);
        }
        getCategoryData();
       
    },[QueryCategory])

    useEffect(() => {
        console.log("ProductList")  
        getProductData();
        window.scrollTo(0, 0);
           
    }, [paging,filter?.price,QueryCategory,filtersort]);

    const getProductData=async()=>{
      
    let list= await getProducts({ page: paging.currentpage, limit: paging.itemlimit, filter: {...filter,category:QueryCategory},order:filtersort });
        console.log(list);
      setcomponentLoader(1);    
      setProductList((pre)=>({totalrecord: list.productdata.count, list: list.productdata.rows, url: list.url}))
    
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }
        })
    }

  
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }

    const FilterProduct=(filterArg)=>{
        console.log('Call Filter Box');
        console.log(filterArg);
        setFilter((pre)=>({...pre,categories:filterArg.categories,price:filterArg?.price}));

    }

       

    return (
        <>
       
            <Header searchkey={filter.searchkey}></Header>
            <section className="shop-details pt-90 pb-90">

                <div className="container">
                    <div className="row">
                    <div className="col-md-3">
                    <FilterComponent categorybox={false} FilterProduct={FilterProduct} categories={filter.categories}></FilterComponent> 
                        </div>
                        
                        <div className="col-md-9">
                            <div className="shop-top-area mb-20">
                                <div className="row">
                                    <div className="col-md-8">
                                    <h2>Product Category {categoryname}</h2>
                                    </div>
                                    <div className="col-md-4"><div className="text-sm-end">
                                            <div className="select-default">
                                                <select name="short" id="short" className="shorting-select" >
                                                    <option value="">Default sorting</option>
                                                    <option value="">ASC</option>
                                                    <option value="">DEC</option>
                                                </select>
                                                {/*<div className="nice-select shorting-select" tabindex="0"><span className="current">Default sorting</span><ul className="list"><li data-value="" className="option selected focus">Default sorting</li><li data-value="" className="option">ASC</li><li data-value="" className="option">DEC</li></ul></div>*/}
                                            </div>
                                        </div></div>
                                </div>
                                
                                
                            </div>
                            
                            <div className="shop-main-area mb-40">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="tab1">
                                        <div className="row">

                                            {

                                                productListData.list.map((item, itemkey) => {
                                                 
                                                   
                                                    return (<div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6" key={item.id}  title={item.productName}>
                                                        <div className="product__item mb-20">
                                                        <Link to={`/product/` + item.productSku}>
                                                            <div className="product__thumb w-img fix">
                                                           
                                                                {
                                                                    productObj.productImage(item, productListData.url,)
                                                                }                                                            
                                                           
                                                                <div class="product__action transition-3">
                                                                    <ul>
                                                                        <li>
                                                                            <WishList id={item.id} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>  </Link>  
                                                            <div className="product__content">
                                                            <div className="product__content_box">
                                                                <div className="product__tag product__tag-4">
                                                                    <span className="sku-box"> SKU : <Link to={`/product/` + item.productSku}>{productObj.displaySKU(item)}</Link></span>
                                                                </div>
                                                               
                                                                
                                                               
                                                                <h3 className="product__title">
                                                                    {productObj.getProductName(item,true,true)}
                                                                   
                                                                </h3>
                                                               
                                                            {productObj.displayPriceQuantity(item)}
                                                            </div>
                                                                <div className="product__select-button">
                                                                    {item.productPrice > 0 &&  item?.productQuantity > 0 && <CartButton product={{ ...item, productImage: ((item.productImage) ? productListData.url + item.productImage : "") }} classes="select-btn w-100"></CartButton>}
                                                                    {(item.productPrice == 0 ||  item?.productQuantity ==0) && <NotifyButton classes="select-btn w-100" item={item}></NotifyButton>}

                                                                </div>

                                                                {

                                                                }

                                                            </div>
                                                        </div>

                                                    </div>)

                                                })

                                            }

                                            {productListData.list.length == 0 && componentLoader!=0 && <Empty/>}

                                            { componentLoader==0 && <Skeleton />}



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-pagination text-center">
                                <nav>
                                    {productListData?.totalrecord > 0 && <Pagination defaultPageSize={paging?.itemlimit} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={productListData.totalrecord} onChange={handlePageChange} />}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default ProductList