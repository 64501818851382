import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const Shipping = function () {


    useEffect(() => {
        window.scrollTo(0, 0)


    }, [])


    return (
        <>
            <Header ></Header>
            <section className="pad-csa-boder shop-details pt-90 pb-90 internal-inner-pages">
               
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                        <div className="pad-csa-boder">
                        <h1 className="titektre">Shipping and Return Policy</h1>
                        <h3 className="policty">Shipping Information:</h3>
                        <ul>
                           <li>We offer Free Delivery on all orders within India.</li>
                           <li> Your order will be processed and dispatched within 1 to 2 business days of receiving payment(Once your order has been shipped, you will receive a confirmation email/what's app message with tracking information to monitor the delivery status.)</li>
                           <li>Please allow 5  to 7 days for your order to arrive, depending on your location and courier service availability.</li>
                           </ul>

                        <h3  className="policty">Return Policy:</h3>
                        <ul>
                           <li>For any reason if you are not satisfied, you may return your item(s) within 7 days of receiving your order.</li>
                           <li> If you receive a damaged or defective item, please contact us immediately with photos of the damage or defect. We will arrange for a replacement or refund as soon as possible at no additional cost to you.</li>
                           <li>To initiate a return, please contact our customer service team at <a href="mailto:dekobuddy@gmail.com">dekobuddy@gmail.com</a>/<a href="tel:919935036578">+919935036578</a> to obtain a return authorization.</li>
                           <li>Returned items must be in their original condition, unused, and with all tags and packaging intact.</li>
                           <li>Custom-made or personalized items may not be eligible for return unless they are defective or damaged upon arrival.</li>
                           <li> Once your return is received and inspected, we will process your refund within 3-4 business days to the original payment method used for the purchase.</li>
                           </ul>
                           <p>By placing an order on our website, you agree to adhere to our shipping and return policy as outlined above.</p>
                        </div>
                        </div>
                        <div className="col-md-1"></div>

                    </div>

                   



                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default Shipping