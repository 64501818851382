import { Button, Form, Input, Switch } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const Registration = (props) => {
    const dispatch = useDispatch();
    const navigate= useNavigate()
    const componentSize = 'large';
    const loginkey = 'login';
    const onFinish = async (values) => {
        
        console.log(values);
        const userData = await authObj.registration(values);
        console.log(userData);
        if (userData !== false) {
           //authObj.setData(userData)
            //dispatch(int());
          const path= await authObj.getRedirectpath();
         
           if(path!==''){
                    navigate(path);
            }else{
                navigate("/")
            }
            
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row align_center">
             
                <div className="ragistraiton_box">
                <div className="image_box">
                <img src="../../images/changing_everything.webp" alt="ragistraiton" />
                    </div>  
                <div className="form_box">
                    <h3>Registration </h3>
                <div className="loginBox">
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your firstName!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your lastName!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Last Name" />
                </Form.Item>

                <Form.Item
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Mobile" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                        {type: 'email',
                        message: 'Please input Valid Email!',}
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Password" />
                </Form.Item>
                <div className="row">
                    <div className="col-12">
                       <div className="creat_account"> 
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Register Now
                        </Button>
                    </div>
                    </div>
                    <div className="col-12">
                       <div className="creat_account"> 
                   
                        <Link to="/login" className='forgot_box'>
                        <button className="btn btn-link px-0" type="button">      Login Now</button>
                    </Link>
                    </div>
                    </div>
                </div>
            </Form>
            </div>
        </div>
                </div>



                </div>
            </div>
        </div>
        <Footer></Footer>


      

    </>)
}
export default Registration