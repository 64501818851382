import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import commonObj from "../../utility/commonclass";
const Section2 = function () {
    const [banner,setBanner]=useState({list:[],url:""});
    useEffect(()=>{
        getBenners();

    },[])

    const getBenners=async()=>{
        let bannerData=await commonObj.getBanners({type:'Section2'});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    } 
    return (<>
       <section className="banner__area pb-40 section-p-7">
            <div className="container">

                <div className="row">
                    {banner?.list?.length > 0 &&  banner?.list?.map((item,itemkey)=>{
                        return (<div className="col-md-4 mx-sp-cat-css">
                        <Link to={(item?.link !=null)?item?.link:""} >
                            <div className="slider__item second-new-section swiper-slide d-flex align-items-center include-bg" style={{
                                backgroundImage: `url(${banner?.url+'/'+item?.image_name})`
                            }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xxl-6">
                                            <div className="slider__content animation-second scale-up-hor-center p-relative z-index-11">


                                                <div className="slider__btn bunkier" data-animation="fadeInUp" data-delay=".4s">
                        <div className="label-box">{item?.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>)
                       })
                    }
                    





                </div>

            </div>
        </section>
    </>)

}

export default Section2;