import { useState, useEffect } from "react";
import { Form, Input,  Button, notification, Col,Select,Spin } from 'antd';
import customerObj from '../../utility/customerClass'
import { useParams,useNavigate } from 'react-router-dom';
import commonObj from '../../utility/commonclass'
const { Option } = Select;
const AddressEdit = () => {
    const navigate=useNavigate();
    const [addressData, setaddress] = useState({});
    const [countryList, setcountry] = useState({ list: [], isload: true });
    const [stateList, setstate] = useState({ list: [], isload: true });
    const [cityList, setcity] = useState({ list: [], isload: true });
    const {id} =useParams();   
    const [form] = Form.useForm(); 
    useEffect(()=>{
          async function getData(){
          const countrydata = await commonObj.getCountryList(); 
          const addressResults =  await customerObj.getAddressDetail(id);        
          setaddress(addressResults);
          setcountry({ list: countrydata.list, isload: true });
          setstate({ list: [], isload: false });
          const statedata = await commonObj.getStateList(addressResults.countryId);
          setstate({ list: statedata.list, isload: true });
          setcity({ list: [], isload: false });
          const citydata=await commonObj.getCityList(addressResults.stateId);
          setcity({list:citydata.list,isload:true});
         
        }
        getData();


    },[])
    const onFinish = async (values) => {
        try {
          // console.log(values)
           customerObj.updateAddress({...values,id:addressData.id,customerId:addressData.customerId}); 
           navigate('/customer/address');
           notification.success({key:'save',message:'Address has been updated successfully'})
        } catch (e) {

        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const selectCountry = async (val, option) => {
        setstate({ list: [], isload: false });
        const statedata = await commonObj.getStateList(val);
        setstate({ list: statedata.list, isload: true });
        console.log(statedata);
    }

    const selectState = async (val, option) => {
        setcity({ list: [], isload: false });
        const citydata=await commonObj.getCityList(val);
        form.setFieldValue('state',val);        
        setcity({list:citydata.list,isload:true});
       
    }

    const selectCity = async (val, option) => {      
        form.setFieldValue('city',val);
     
    }
    return (<>
        <div className="address-box">
           
        {addressData.firstName && <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={addressData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your First Name!',
                    },
                ]}

            >
                <Input className={"form-control"} placeholder="First Name" />
            </Form.Item>

            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Last Name!',
                    },
                ]}

            >

                <Input className={"form-control"} placeholder="Last Name" />
            </Form.Item>

            <Form.Item
                name="primaryEmail"
                label="Email"
                rules={[
                    {
                        required: true,
                        message: 'Please input Email!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Email" />
            </Form.Item>

            <Form.Item
                name="mobileNumber"
                label="Mobile"
                rules={[
                    {
                        required: true,
                        message: 'Please input Mobile!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Mobile" />
            </Form.Item>
            

            <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select Country',
                        },
                    ]}
                >

                    <Select
                        showSearch

                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().includes(input.toLowerCase())
                        }}
                        filterSort={(optionA, optionB) =>

                            optionA.children.localeCompare(optionB.children)
                        }
                        onSelect={selectCountry}
                    >
                        {countryList.list.map((countryItem, citemKey) => {
                            return <Option value={countryItem.id} key={citemKey}>{countryItem.name}</Option>

                        })}

                    </Select>
                </Form.Item>
                <Form.Item
                    name="state"
                    label="State"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select State',
                        },
                    ]}
                   
                >

                    <Select
                        showSearch

                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().includes(input.toLowerCase())
                        }}
                        filterSort={(optionA, optionB) =>

                            optionA.children.localeCompare(optionB.children)
                        }
                        onSelect={selectState}
                        defaultValue={addressData.state}
                    >
                        {stateList.list.map((stateItem, sitemKey) => {
                            return <Option value={stateItem.id} key={sitemKey}>{stateItem.name}</Option>
                        })}
                    </Select>
                    {stateList.isload == false && <Spin size="small" style={{ position: 'absolute', right: 32, top: 8 }} />}
                </Form.Item>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select City',
                        },
                    ]}
                >

                    <Select
                        showSearch

                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().includes(input.toLowerCase())
                        }}
                        filterSort={(optionA, optionB) =>

                            optionA.children.localeCompare(optionB.children)
                        }
                        onSelect={selectCity}
                        defaultValue={addressData.city}
                       
                    >
                        {cityList.list.map((cityItem, cityitemKey) => {
                            return <Option value={cityItem.id} key={cityitemKey}>{cityItem.name}</Option>
                        })}
                    </Select>
                    {cityList.isload == false && <Spin size="small" style={{ position: 'absolute', right: 32, top: 8 }} />}
                </Form.Item>
            <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                    {
                        required: true,
                        message: 'Please input Pincode!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Pincode" />
            </Form.Item>
            <Form.Item
                name="addressLine1"
                label="Address 1"
                rules={[
                    {
                        required: true,
                        message: 'Please input Address 1',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Address 1" />
            </Form.Item>
            <Form.Item
                name="addressLine2"
                label="Address 2"
                rules={[
                    {
                        required: true,
                        message: 'Please input Address 2!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Address 2" />
            </Form.Item>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8"> <Button type="primary" htmlType="submit" className=""> Save </Button></div>

            </div>

        </Form>}

        </div>


    </>)

}
export default AddressEdit;