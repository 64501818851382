import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import commonObj from "../../utility/commonclass";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const HomeSlider = function (props) {

    const [banner,setBanner]=useState({list:[],url:""});
    useEffect(()=>{
    getBenners();

    },[])

    const getBenners=async()=>{
        let bannerData=await commonObj.getBanners({type:'HomeSlider'});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    return (<>

        <section className="slider__area" id="whychooseus">
            <div className="slider__active swiper-container">
            <h3 className="cargrv paxao">Why Choose Us</h3>
                <div className="">
                <div className="swiper-wrapper">                
                    {banner?.list?.length >
                     0 &&  
                     <OwlCarousel navContainerClass="main-slider-pagination" dots={false} autoplay={false} className='owl-theme product-carousel slider__title' id="bxslider-home4" loop nav items={1} >
                        <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" >  
                             <div className="col-md-12"> 
                               <Link to="/"><img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/choosus/DSC_4599 SLIDER.jpg")} />
                                <div className="confdi-choose">
                                <h3> In House Production Facility.No Middle Man</h3>
                               <div className="axax-view-moe">
                               <Link to="/cart">View More</Link>
                               </div>
                               </div>
                               </Link>
                            </div>                        
                            </div>
                    <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" >  
                             <div className="col-md-12"> 
                               <Link to="/"><img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/choosus/DSC_4642 SLIDER.jpg")} />
                               <div className="confdi-choose">
                               <h3> Reasonable Prices</h3>
                               <div className="axax-view-moe">
                               <Link to="/cart">View More</Link>
                               </div>
                               </div>
                               </Link>
                            </div>                        
                            </div>                 
                    <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" >  
                             <div className="col-md-12"> 
                               <Link to="/"><img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/choosus/DSC_4657 SLIDER.jpg")} />
                               <div className="confdi-choose">
                               <h3> Production Under Direct Guidance Of Skilled Artisans</h3>
                               <div className="axax-view-moe">
                               <Link to="/cart">View More</Link>
                               </div>
                               </div>
                               </Link>
                            </div>                        
                            </div>
                   <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" >  
                             <div className="col-md-12"> 
                               <Link to="/"><img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/choosus/DSC_4666 SLIDER.jpg")} />
                               <div className="confdi-choose">
                               <h3>Use Of Best Quality,Nature Friendly Locally Sourced Raw Materials</h3>
                               <div className="axax-view-moe">
                               <Link to="/cart">View More</Link>
                               </div>
                               </div>
                               </Link>
                            </div>                        
                            </div>
                    <div className="slider__item swiper-slide d-flex align-items-center include-bg dvdcscscascxaxaxaxaxa" >  
                             <div className="col-md-12"> 
                               <Link to="/"><img style={{  width: '100%', maxWidth: '100%'}} src={require("../../images/choosus/IMG-20240131-WA0196 SLIDER.jpg")} />
                               <div className="confdi-choose">
                               <h3>Products As Per Latest Trends, Designs and Colours</h3>
                               <div className="axax-view-moe">
                               <Link to="/cart">View More</Link>
                               </div>
                               </div>
                               </Link>
                            </div>                        
                            </div>
                    
                    </OwlCarousel>
                }
                </div>
                </div>
            </div>
        </section>
    </>)

}
export default HomeSlider;