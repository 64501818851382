import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import { Empty } from 'antd';
import 'font-awesome/css/font-awesome.min.css'
import { useEffect,useState } from 'react';
import productObj from '../../utility/productClass';
import CartButton from '../Product/CartButton';

const CategoryProducts2 = function (props) {
    const categoryid=props.categoryid;
    const side=(props.side)?props.side:'left';
    const [category,setlist]=useState({});
    const [productListData, setProductList] = useState({ totalrecord: 0, list: [], url: "" });

    useEffect(()=>{
       

        
       async function getCategoryDetail(){
           if(categoryid){
            const data =await productObj.getCategoryDetail({id:categoryid});       
            setlist(data); 
           }
       
       }

       async function getgetProducts(){
        if(categoryid){
            const data =await productObj.getProductData({filter:{category:categoryid}});       
            console.log(data);
            setProductList({list:data?.productdata?.rows, totalrecord:data?.productdata?.count,url:data.url});
            console.log(productListData.url)
        }

        //setlist(data); 
       }
 

       getCategoryDetail();
       getgetProducts();
    },[props.categoryid])

    return (<>

                  

                   
            <div className="product__item-wrapper pt-15 categaert-nre-anim">
                <div className="product__top section__title-line d-sm-flex align-items-start mb-35">
                    <div className="section__title-wrapper mr-30">
                       <h3 className="cargrv">Best Seller</h3>    
                    </div>
                   
                </div>
                <div className="product__tab-content">
                    <div className="tab-content" id="myTabContent">
                     
                        {productListData?.list !=undefined && 
                            productListData.list.length > 0 && 
                       
                            <OwlCarousel className="product__item-slider common-nav owl-carousel desktop-product" dots={false} items={5} loop nav>
                            {productListData.list.map((item)=>{

                                return (
                                    <div className="product__item prod-2 mb-20" key={item.id}>
                                        <div className="product__thumb w-img fix">
                                            <Link to={`/product/` + item.productSku}>
                                                {productObj.productImage(item,productListData.url)}                                               
                                            </Link>
                                           

                                        </div>
                                        <div className="product__content prod-contant-2 text-center">  
                                                                              
                                            <h3 className="product__title">
                                                {productObj.getProductName(item,true,true,30)}
                                               
                                            </h3> 
                                            <div className="product__price">
                                                <span className="price">Starting {productObj.PriceDisplay(item)}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )
                            })}
                            
                           
                            </OwlCarousel>
}   

{ productListData?.list !=undefined &&  productListData.list.length ==0 && 
    <div ><Empty style={{minHeight:200}} image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
}
                       
                       
                    </div>
                </div>
                 <div className="product__tab-content">
                    <div className="tab-content" id="myTabContent">
                     
                        {productListData?.list !=undefined && 
                            productListData.list.length > 0 && 
                       
                            <OwlCarousel className="product__item-slider common-nav owl-carousel mobile-product" dots={false} items={2} loop nav>
                            {productListData.list.map((item)=>{

                                return (
                                    <div className="product__item prod-2 mb-20" key={item.id}>
                                        <div className="product__thumb w-img fix">
                                            <Link to={`/product/` + item.productSku}>
                                                {productObj.productImage(item,productListData.url)}                                               
                                            </Link>
                                           

                                        </div>
                                        <div className="product__content prod-contant-2 text-center">  
                                                                              
                                            <h3 className="product__title">
                                                {productObj.getProductName(item,true,true,30)}
                                               
                                            </h3> 
                                            <div className="product__price">
                                                <span className="price">Starting {productObj.PriceDisplay(item)}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )
                            })}
                            
                           
                            </OwlCarousel>
}   

{ productListData?.list !=undefined &&  productListData.list.length ==0 && 
    <div ><Empty style={{minHeight:200}} image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
}
                       
                       
                    </div>
                </div>
            </div>
       
     

                   
    </>)

}

export default CategoryProducts2;