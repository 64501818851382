import { useState, useEffect } from "react";
import { Empty, Card, Button, Row, Col, Divider } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined, SearchOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import customerObj from '../../utility/customerClass'
import { Link, useNavigate } from "react-router-dom";
const Address = () => {
    const [addresslist, setaddress] = useState({ list: [] });
    const [defaultAddress, setDefaultaddress] = useState(0);
    const navigate = useNavigate()
    useEffect(() => {
        async function getData() {
            const addressdata = await customerObj.getAddress();
            setaddress({ ...addresslist, list: addressdata.list });
            // console.log(addresslist);
        }
        getData();


    }, [])

    const updateDefault = async (id, isDefault) => {
       await customerObj.updateDefaultAddress({ id: id, isDefault: isDefault });        
        const newlist = addresslist.list.map((item, itemkey) => {
            if (id === item.id) {
                item.isDefault = 1
            } else {
                item.isDefault = 0
            }
            return item;

        })
        setaddress((pre)=>{

            return {...pre , list: newlist};
        })
        

    }

    const { Meta } = Card;
    return (<>
        <section className="address-box">

            <Row>
                <Col span={18}></Col>
                <Col span={6} className="right-align">

                    <Button type="primary" icon={<SearchOutlined />} onClick={(e) => { navigate("/customer/add-address") }}>
                        Add New
                     </Button>
                </Col>
            </Row>
            <Divider />
            <Row gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}>
                {addresslist.list.map((item, itemkey) => {
                    let dClass = "default-address-box";
                    if (item.isDefault === 1) {
                        dClass = " default-address-box active";
                    }



                    return (<Col span={8} style={{ marginBottom: '15px' }}>
                        <Card

                            actions={[
                                <Link to={`/customer/address/${item.id}`} >{<EditOutlined></EditOutlined>}</Link>,

                            ]}
                        >
                            {item.isDefault === 0 && <div className="default-address-box"><Link to="" onClick={(e) => { updateDefault(item.id, 1) }}><StarOutlined /></Link></div>}
                            {item.isDefault === 1 && <div className="default-address-box active"><Link to="" ><StarFilled /></Link></div>}
                            <p>Name : {`${item.firstName} ${item.lastName}`}</p>
                            <p>Email : {item.primaryEmail}</p>
                            <p>Mobile : {item.mobileNumber}</p>
                            <p>Address : {`${item.addressLine1} ${item.addressLine2}`}</p>
                            <p>Pincode : {`${item.pincode}`}</p>
                        </Card>
                    </Col>)
                })}


            </Row>

        </section>


    </>)

}
export default Address;