import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MyImage = (image) => {
  console.log(image);
  return(
  
    <LazyLoadImage
      style={{height:(image?.height!=undefined)?image?.height:"auto"}}
      width="100%"
      src={image.src} // use normal <img> attributes as props
      placeholderSrc={require("../../images/product.png")}
      />
    
)};

export default MyImage;