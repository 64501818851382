import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import 'font-awesome/css/font-awesome.min.css'
import productObj from '../../utility/productClass'
import React, { useState,useEffect } from 'react';
const TopCatagory = function (props) {
    const [categorylist,setlist]=useState({list:[],url:""});
    useEffect(()=>{
        async  function  getCategoryData(){
            const data =await productObj.getCategory({isHome:1,orderby:"erp_cat_id",ordertype:"ASC"});
            console.log(data);
            setlist({list:data.category,url:data.url});

        }

        getCategoryData();

    },[])
    return (<>
   
    
                {categorylist.list.length > 0 &&           
                categorylist?.list.map((item)=>{
                    return (<div className="col-md-3 col-6 mb-10" key={item.id}>
                        <div className="product__item mb-20">
                            <div className="product__thumb w-img fix">
                            <Link to={productObj.categoryUrl(item)}>
                                {productObj.categoryImage(item,categorylist.url)}
                              
                            </Link>
                            </div>
                            <div className="product__content text-center">
                            <Link to={productObj.categoryUrl(item)}>{item.categoryName}</Link>
                             </div>  
                      
                   
                   
                </div></div>)
                })
               
    }
  
    </>)

}
export default TopCatagory;