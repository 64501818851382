import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import commonObj from "../../utility/commonclass";
import { useEffect, useState } from 'react';


const HomeSlider = function (props) {
    const [banner,setBanner]=useState({list:[],url:""});
    useEffect(()=>{
        getBenners();

    },[])

    const getBenners=async()=>{
        let bannerData=await commonObj.getBanners({type:'HomeSlider'});
        console.log("Banner",bannerData);
        if(bannerData?.data?.list !=null && bannerData?.data?.list !=undefined ){
            setBanner({list:bannerData?.data?.list,url:bannerData?.data?.url});
        }

    }

    return (<>

        <section className="slider__area">
            <div className="slider__active swiper-container">
                <div className="swiper-wrapper">                
                    {banner?.list?.length > 0 &&  <OwlCarousel
                        navContainerClass="main-slider-pagination"
                        dots={false}
                        autoplay={true}
                        className='owl-theme product-carousel slider__title' id="bxslider-home4" loop nav items={1} >
                        {banner?.list?.length > 0 && banner?.list?.map((item,itemkey)=>{
                            return ( <div key={item?.id} className="slider__item slider__height swiper-slide d-flex align-items-center include-bg" style={{
                                backgroundImage: `url(${banner?.url+'/'+item?.image_name})`
                            }}>                           
                            </div>)
                        })}                       
                    </OwlCarousel>
                }
                </div>
            </div>
        </section>
    </>)

}
export default HomeSlider;