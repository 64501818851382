import Header from '../Header/Header';
import Footer from '../Footer/index';
import cartObj from '../../utility/cartClass'
import commonObj from '../../utility/commonclass'
import customerObj from '../../utility/customerClass'
import Payment from '../Payment/index';
import { Card, notification, Collapse, Empty, Radio,Space } from 'antd';
import Summary from './summary'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
const { Panel } = Collapse;
const CheckoutPage = (props) => {
    const [checkoutData, setCheckout] = useState({ address: {}, payments: [] })
    const [selectPayment, setpayment] = useState(0);
    const [paymentMode, setpaymentMode] = useState(0);
    const [orderFullDetal, setOrderDetail] = useState({});
    const cartData = cartObj.cartData; 
    useEffect(() => {
        const getData = async () => {
            let  addressResults = await customerObj.getDefaultAddress();
            const paymentData = await commonObj.getPaymentMethods();
            console.log('addressResults ');
            console.log(addressResults);
            if(addressResults===null){
                addressResults={};
            }
            console.log('addressResults ');
            console.log(addressResults);
            setCheckout((pre) => {
                return { ...pre, address: addressResults, payments: paymentData.list }
            })

        }
        getData();



    }, [])

    const selectPaymentoption=(id)=>{
        setpayment(id);

    }
    

    const makepayment =async ()=>{

        if(!checkoutData.address.id){
            notification.warning({key:'makepayment',message:"Please Select Address"});
            return false;
        }
        if(!selectPayment){
            notification.warning({key:'makepayment',message:"Please Select Payment Method"});
            return false;
        }
        console.log(checkoutData.address);
        let shippingMethod="";
        let shippingAmount=0;


        if(Object.keys(cartObj.ShippingData).length > 0){

            shippingAmount=cartObj.ShippingData['amount'];
            shippingMethod="Fedex";
        }
        const order={
            customerId:0,
            addressId:checkoutData.address.id,
            email:checkoutData.address.primaryEmail,
            mobile:checkoutData.address.mobileNumber,         
            currency:'$',            
            taxAmount:0,
            shippingAmount:shippingAmount,
            shippingMethod:shippingMethod,         
            discountAmount:'',
            couponDetail:'',
            paymentId:selectPayment,


        };
        const orderData={orderItem:cartData,order:order}
        console.log(orderData);
        const orderDetail=await customerObj.createOrder(orderData);
        console.log(orderDetail);
        if(orderDetail.order.id > 0){
            console.log('update Order');
            console.log(orderDetail.order);
            await setOrderDetail(orderDetail.order);
            await setpaymentMode(1);
           
        }
       
    }


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            { paymentMode==0 && <div className="container">
                {Object.keys(cartData).length == 0 && <Empty description="Cart is Empty"></Empty>}
                {Object.keys(cartData).length > 0 && <div className="row">
                    <div className="col-md-9">
                        <div className="product-content-right">
                            <div className="woocommerce">
                                <Collapse accordion={false} defaultActiveKey={[1, 2]}>
                                    <Panel header=" Delivery address" key="1" >
                                        <Card>
                                            {
                                                Object.keys(checkoutData.address).length > 0 && <ul className="address-box">
                                                <li>{`${checkoutData?.address?.firstName} ${checkoutData?.address?.lastName} (${checkoutData?.address?.mobileNumber})`}</li>
                                                <li>{`${checkoutData.address?.addressLine1} ,  ${checkoutData.address?.addressLine1}`}</li>
                                                <li>{checkoutData?.address?.city && checkoutData?.address?.city.name + ' , '}
                                                    {checkoutData?.address.state && checkoutData?.address?.state.name + ' , '}
                                                    {checkoutData?.address.country && checkoutData?.address?.country?.name + ' , '}
                                                    {checkoutData?.address?.pincode}
                                                </li>

                                            </ul>
                                            }
                                            {Object.keys(checkoutData.address).length ==0 && <Link to="/customer/add-address/">Create New Address</Link>}
                                            
                                        </Card>
                                    </Panel>
                                    <Panel header="Select a payment method " key="2">
                                        {
                                            checkoutData.payments.map((item, itemkey) => {

                                                return (<>
                                                  <div className="payment-item" onClick={(e)=>selectPaymentoption(item.id)}>
                                                      {selectPayment===item.id && <span className="icon-box"><i class="fa fa-dot-circle-o" aria-hidden="true"></i></span>}
                                                      {selectPayment !== item.id && <span className="icon-box"><i class="fa fa-circle-o" aria-hidden="true"></i></span>}
                                                  

                                                      <label>{item.paymentLabel}</label>
                                                  </div>  
                                                   
                                                </>
                                                )
                                            })
                                        }
                                    </Panel>

                                </Collapse>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">

                        <Summary makepayment={makepayment} address={checkoutData.address}></Summary>
                        <div className="row sconccc-scccc">
                     <div className="col-md-1 dfghj-dfdce_erghgf">
                     <img  src={require("../../images/shield.png")} />
                     </div>
                     <div className="col-md-10">
                     <p>Safe and Secure Payments. Easy returns. 100% Authentic products.</p>
                     </div>
                     </div>


                    </div>
                </div>}
            </div> }
            { paymentMode==1 && <Payment orderFullDetal={orderFullDetal}></Payment>}

            
        </div>
        <Footer></Footer>
    </>)

}

export default CheckoutPage;