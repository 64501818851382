let development={
url:'https://dekobuddy.com:3002/',
 //url:'http://localhost:3002/',
//url:'https://apisuperstore.virtualstacks-demosite.com/',
sessionkey:'_dekhobuddy',
adminsessionkey:'_adminitstore',
pathKey:'_dekobuddy.path',
copyright:'Copyright ©2024 ',
email:'sales@dekobuddy.com',
mobile:'9876543210',
instagram:'dekobuddy@instagram.com',

}

let production={
    url:'',
    sessionkey:'_dekobuddy',
    adminsessionkey:'_adminitstore',
    cartKey:'_dekobuddy._cart',
    pathKey:'_dekobuddy.path',
    copyright:'Copyright ©2023 ',
    email:'sales@dekobuddy.com',
    mobile:'9876543210',
    instagram:'dekobuddy@instagram.com',

    
}
let config=(process.env.REACT_APP_ENVIRONMENT==='production')?production:development;

export default config;
