import axios from '../config/axios';
import { notification } from 'antd';

function getResponseHandler(response) {

    if (response.data.status === 1) {
        return response.data.data;
    } else {
        notification.error({ message: response.data.errormessage });
        return [];
    }

}
export const getCustomers = async (data = {}) => {

    try {
        const rowData = await axios.post('/user/getcustomer', data);
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getCustomerDetail = async (id=0) => {

    try {
        const rowData = await axios.post('/user/getcustomerdetail', {id:id});
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const update = async (id=0,data) => {

    try {
        const response = await axios.post('/user/update', {id:id,data:data});
        console.log(response);
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const updatePassword = async (id=0,password) => {

    try {
        const response = await axios.post('/user/updatepassword', {id:id,password:password});       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getwishlist = async (id=0) => {

    try {
        const response = await axios.post('/user/getwishlist', {id:id});       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const deletewishlist = async (id=0) => {

    try {
        const response = await axios.post('/user/deletewishlist', {id:id});       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getCustomerAddress = async (id=0) => {

    try {
        const response = await axios.post('/user/getAddress', {id:id});       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getCustomerAddressDetail = async (customerId=0,id=0,isDefault=0) => {

    try {
        const response = await axios.post('/user/getAddressDetail', {id:id,customerId:customerId,isDefault:isDefault});       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const saveAddressService = async (data) => {

    try {
        const response = await axios.post('/user/saveAddress', data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const updateAddressService =async (data)=>{
    try {
        const response = await axios.post('/user/updateAddress', data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }

}

export const updateDefaultAddressService =async (data)=>{
   
    try {
        const response = await axios.post('/user/updateDefaultAddress', data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }

}


export const createOrderService =async (data)=>{
   
    try {
        const response = await axios.post('/user/createOrder', data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }

}


export const updateOrderPaymentStatus =async (data)=>{
   
    try {
        const response = await axios.post('/user/updateOrderPaymentStatus', data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }

}


export const callListApi =async (url,data)=>{
   
    try {
        const response = await axios.post(url, data);       
        return getResponseHandler(response);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }

}











