import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import { AutoComplete, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import Header from './components/HeaderHome/Header';
import HomeSlider from './components/Header/HomeSlider'
import Section2 from './components/Home/Section2'
import AdvBanner from './components/Home/AdvBanner'
import WhyChoose from './components/Home/WhyChoose'

import TopCatagory from './components/Category/TopCategory'
import HomeCategory from './components/Category/HomeCategory'
import CategoryProduct from './components/Category/CaptegoryProduct'
import Footer from './components/Footer/index';
import productObj from './utility/productClass'
import SaleProduct from './components/Home/SaleProuct'
import Trending from './components/Home/Trending'
import SpecialOffer from './components/Home/SpecialOffer'
import Article from './components/Article/Article'
import PriceFilter from './components/Home/PriceFilter'
import CategoryProducts from './components/Home/CategoryProducts'
import CategoryProducts2 from './components/Home/CategoryProducts2'
import CategoryProducts3 from './components/Home/CategoryProducts3'

var bgimg2 = require("./images/In-house-production-facility.jpg");
//import commonObj from "../../utility/commonclass";
const Home = function () {
    const [categorylist, setlist] = useState({ list: [], url: "" });


    useEffect(() => {
     

    }, [])

    


    return (<><Header></Header>
        <HomeSlider></HomeSlider>
        <Section2></Section2>        
        <section className="product__area categaert-nre-anim">
            <div className="container">
                <div className="row">
                <h3 className="cargrv">Product Category</h3>  
                <HomeCategory></HomeCategory>
                   {/* <div className="col-md-3" data-id={'id-18'}><CategoryProducts categoryid="18"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-12'}><CategoryProducts categoryid="12"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-13'}><CategoryProducts categoryid="13"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-11'}><CategoryProducts categoryid="11"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-2'}><CategoryProducts categoryid="2"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-3'}><CategoryProducts categoryid="3"></CategoryProducts></div>

                    <div className="col-md-3" data-id={'id-7'}><CategoryProducts categoryid="7"></CategoryProducts></div>
    */}
                    
                </div>
            </div>
        </section>
       
        <section className="product__area">
            <div className="container">
                <div className="row">
                

                    <div className="col-md-12"><AdvBanner type={"Section3"}></AdvBanner></div>
                    
                </div>
            </div>
        </section>
        
        <section className="product__area">
            <div className="container">
                <div className="row">
                
                <div className="col-md-12" data-id={'id-2'}><CategoryProducts3 categoryid="2"></CategoryProducts3></div>
                    
                     <div className="col-md-12"><AdvBanner type={"Section5"}></AdvBanner></div>
                   {/*  <div className="col-md-6"><div className="row">

                    <div className="col-md-12">
                                <div className="bag_image">
                                <div className="nrew_cjcnc"  style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                 <h3 className="ndwcnscw">Empowering Skilled Artisans From <br/><spam>Carpet City of India</spam> </h3>
                                </div>
                                </div>
                             </div>  

                    </div></div>*/}

                    
                    
                </div>
            </div>
        </section>
              

        <section className="product__area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" data-id={'id-2'}><CategoryProducts2 categoryid="2"></CategoryProducts2></div>
                </div>
            </div>
        </section>

        <PriceFilter></PriceFilter>

        <section className="product__area backdvew">
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-12"><WhyChoose></WhyChoose>  </div>
                </div>
            </div>
        </section>

       

        
    
     
     
        {/*<Article></Article>*/}
        <section className="features__area pb-30 section-4 mg-12">
            <div className="container">
                <div className="row">
                   
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6">
                        <div className="features__item text-center mb-30 features__item-border">
                            <div className="features__icon">
                                <img className="csr-cse0-scs" src={require("./images/2.png")} alt=""/>
                            </div>
                            <div className="features__content">
                                <h3 className="features__title">Easy Returns</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6">
                        <div className="features__item text-center mb-30 features__item-border">
                            <div className="features__icon">
                                <img className="csr-cse0-scs" src={require("./images/3.png")} alt=""/>
                            </div>
                            <div className="features__content">
                                <h3 className="features__title">Free Shipping</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6">
                        <div className="features__item text-center mb-30">
                            <div className="features__icon">
                                <img className="csr-cse0-scs" src={require("./images/4.png")} alt=""/>
                            </div>
                            <div className="features__content">
                                <h3 className="features__title">24/7 Support</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="features__item text-center mb-30">
                            <div className="features__icon">
                                <img className="csr-cse0-scs" src={require("./images/5.png")} alt=""/>
                            </div>
                            <div className="features__content">
                                <h3 className="features__title">Secure Payment</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <Footer></Footer></>)

}

export default Home;