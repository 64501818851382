/*import {UserOutlined} from '@ant-design/icons'*/
import { Button,  Form, Input,notification } from 'antd';
import Service from '../services/index';
import AdminAuthClass from '../utility/adminauthclass';
import {useNavigate} from 'react-router-dom';
export default function AdminLoginLayout(){
  console.log('Lonin Layout');
const navigate=useNavigate();
const onFinish = (values) => {
        console.log('Success:', values);
        Service.Loginservice.getAdminLogin(values).then((result)=>{
         if(!result.status){
            notification.error({
              message:result.errormessage
            })
         }else{
          AdminAuthClass.setUserSession(result.data.token);
            notification.success({
              message:result.message
            });            
            navigate('/admin');
            

         }

        }).catch((err)=>console.log(err));


        
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

return(
<>
<div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card-group d-block d-md-flex row">
              <div className="card col-md-7 p-4 mb-0">
                <div className="card-body">
                <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                >

                  <h1>Login</h1>
                  <p className="text-medium-emphasis">Sign In to your account</p>
                
                 
                    <Form.Item
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                        ]}
                     >
                        { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                        <Input  className={"form-control"} placeholder="Username" />
                     </Form.Item>

                     <Form.Item
                       name="password"
                       rules={[
                         {
                           required: true,
                           message: 'Please input your Password!',
                         },
                       ]}
                     >
                        { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                        <Input  className={"form-control"} placeholder="Password" />
                     </Form.Item>
                  
                  <div className="row">
                    <div className="col-6">
                    <Button type="primary" htmlType="submit" className="login-form-button">
                         Log in
                     </Button>
                    </div>
                    
                  </div>

                    </Form>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
</>

) 

}