import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const Warranty = function () {


    useEffect(() => {

        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        
        <div className="container">
                    <div className="row">
                    <div className="col-md-1"></div>
                        <div className="col-md-10">
                         <div className="pad-csa-boder">
                         <h1 className="titektre">Terms and Conditions</h1>
                         <p class="content-p">By visiting this website, you unconditionally agree with our terms and conditions. Each purchase made through the services of this website shall be subject to these Online Shopping Terms and Conditions. This site is possessed and operated by Dekobuddy . The domain name www.dekobuddy.com i.e. the website is registered in the name of Dekobuddy , an incorporated organization under the Companies Act, 1956 with its registered office at Sarroi, Bhadohi -221401 INDIA .</p>
                         <h3 className="policty">Product Visual Discrepancies:</h3>
                         <p class="content-p">Due to variations in screen settings and lighting conditions, colors and textures of carpets displayed on the website may appear slightly different from the actual product. We strive to provide accurate representations but cannot guarantee exact matches.</p>
                         <h3 className="policty">Pricing</h3>
                         <p class="content-p">Prices displayed on the website are subject to change without notice. All prices are in Indian Rupees (INR) unless otherwise stated.</p>
                         <h3 className="policty">Order Acceptance</h3>
                         <p class="content-p">Your placement of an order does not constitute acceptance of that order. We reserve the right to accept or decline your order for any reason, including but not limited to product availability and pricing errors.</p>
                         <h3 className="policty">Payment</h3>
                         <p class="content-p">Payment for orders placed on the website must be made in full at the time of purchase. We accept payment via various secure payment methods.</p>
                         <h3 className="policty">Shipping and Delivery</h3>
                         <p class="content-p">We aim to process and ship orders in a timely manner. However, delivery times may vary depending on your location and product availability. </p>
                         <h3 className="policty">Return and Exchange Policy</h3>
                         <p class="content-p">We offer a hassle-free return and exchange policy within a specified period. Please refer to our Returns & Exchanges page for more information.</p>
                         <h3 className="policty">Privacy Policy</h3>
                         <p class="content-p">We respect your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for details on how we collect, use, and disclose your information.</p>
                         <h3 className="policty">Website Copyright</h3>
                         <p class="content-p">All content on this website, including images, text, logos, and designs, are the intellectual property of Dekobuddy and are protected by copyright laws. Unauthorized use, reproduction, or distribution of any content is strictly prohibited.</p>
                         <h3 className="policty">Credit Card/Debit Card Particulars:</h3>
                         <p class="content-p">We use secure payment gateways to process credit card and debit card transactions. By making a purchase on this website, you agree to provide accurate and complete payment information. We do not store or have access to your card details.</p>
                         <h3 className="policty">Trademark</h3>
                         <p class="content-p">The trademarks, logos, and service marks displayed on this website are registered and unregistered trademarks of Dekobuddy  and its licensors. You are prohibited from using any of these marks without prior written consent.</p>
                         <h3 className="policty">Arbitration</h3>
                         <p class="content-p">Any disputes or claims arising out of or relating to your use of this website or purchase of products shall be resolved through arbitration in accordance with the Arbitration and Conciliation Act, 1996. The seat of arbitration shall be Bhadohi (U.P), India.</p>
                         <h3 className="policty">Governing Laws</h3>
                         <p class="content-p">These terms and conditions shall be governed by and construed in accordance with the laws of India. Any legal action or proceeding arising out of or relating to these terms shall be brought exclusively in the courts located in Bhadohi (U.P) , India.</p>
                         <h3 className="policty">Modification of Terms</h3>
                         <p class="content-p">We reserve the right to modify these terms and conditions at any time without prior notice. Changes will be effective immediately upon posting on the website.</p>
                         <p class="content-p">By using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
                         </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )

}
export default Warranty