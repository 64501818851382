import axios from '../config/axios';
import { notification } from 'antd';


const common = {
    getCountry:(data) => axios.post('/getCountry',data).then((res)=>res.data.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
    getState:(countryId) => axios.post('/getState',{countryId:countryId}).then((res)=>res.data.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
    getCity:(stateId) => axios.post('/getCity',{stateId:stateId}).then((res)=>res.data.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
    getPaymentMethods:() =>axios.post('/getPaymentMethods',{}).then((res)=>res.data.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
    callApi:(url,data) =>axios.post(url,data).then((res)=>res.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
   

}
export default common;