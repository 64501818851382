
import cartObj from '../../utility/cartClass'
import {Link} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Card, notification, Collapse, Empty, Radio,Space,Spin } from 'antd';
const Summary = (props) => {
    const cartData = cartObj.cartData;
    console.log(cartData);
    const address=props.address;   
    const [shippingdetail,setshippingdetail] =useState({data:{},loader:true});
    //cartData.address=address;

    useEffect(()=>{
       async function getData(){
           const Shipping= await cartObj.calculateShiping(address);
           console.log(Shipping);
           if(Shipping !== false){
             setshippingdetail({data:Shipping,loader:false});
           }
         
       }

       getData();

    },[props.address]);

    return (<>
        <div className="cart_totals ">

            <table cellspacing="0">
                <thead>
                    <tr>
                        <th colspan="2"><h3>Cart Totals</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="cart-subtotal">
                        <th>Cart Subtotal</th>
                        <td><span className="amount">{cartObj.cartGrossTotalDisplay()}</span></td>
                    </tr>

                    <tr className="shipping">
                        <th>Shipping and Handling</th>
                        <td>
                        {shippingdetail.loader==true && <Spin></Spin>}
                            {shippingdetail.loader==false && cartObj.shippingTotalDisplay()}</td>
                    </tr>

                    <tr className="order-total">
                        <th>Order Total</th>
    <td><strong><span className="amount"> {cartObj.cartTotalDisplay()}</span></strong> </td>
                    </tr>

                    <tr >
                        <th colspan="2">
                            
                        {shippingdetail.loader==false &&  <Link to="" onClick={()=>props.makepayment()}  className='btn'>Payment</Link>}
                        {shippingdetail.loader==true &&  <Link to="" disabled className='btn'>Payment</Link>}
                        </th>

                    </tr>
                </tbody>
            </table>

        </div>
    </>)

}

export default Summary;