import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const TrackOrder = function () {


    useEffect(() => {
        window.scrollTo(0, 0);


    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        <h1 className="section-title">Track Order</h1>
        <div className="container">
                    <div className="row">
                    <div className="col-md-2"></div>
                        <div className="col-md-8">

                           
                            <p className="content-p text-center">coming soon</p>

                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default TrackOrder