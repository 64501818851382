import COD  from './COD';
import PAYPAL from './PAYPAL';
const Payment = (props) =>{
const order=props.orderFullDetal;
console.log('Payment');
console.log(order);
    return (
        <div className="container">
            {order.paymentId==1 && <COD order={order}></COD>}
            {order.paymentId==2 && <PAYPAL order={order}></PAYPAL>}

            
        </div>

    )

}
 export default Payment;