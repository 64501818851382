import { Button, Form, Input, Switch, notification } from 'antd';
import authObj from '../../utility/authclass';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserRedux } from '../../redux/action/userAction'
const Profile = () => {
    const dispatch = useDispatch();
    const [userData, setuserData] = useState({});


    useEffect(() => {
        async function getdata() {
            const user = await authObj.getUserData();
            setuserData({ ...user });
        }
        getdata();
    }, [])

    const onFinish = async (values) => {
        try {
            delete values.email;
            const res = await authObj.updateCustomer(userData.id, values);
            console.log(res);
            if (res[0]) {
                dispatch(updateUserRedux(values));
                notification.success({ key: 'update', message: 'Profile has been saved successfully' })
            }

        } catch (e) {

        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    return (<>
        {userData.firstName && <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={userData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your First Name!',
                    },
                ]}

            >
                <Input className={"form-control"} placeholder="First Name" />
            </Form.Item>

            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Last Name!',
                    },
                ]}

            >

                <Input className={"form-control"} placeholder="Last Name" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Email"
                rules={[
                    {
                        required: true,
                        message: 'Please input Email!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Email" disabled={true} />
            </Form.Item>

            <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                    {
                        required: true,
                        message: 'Please input Mobile!',
                    },
                ]}
            >

                <Input className={"form-control"} placeholder="Mobile" />
            </Form.Item>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8"> <Button type="primary" htmlType="submit" className=""> Save </Button></div>

            </div>

        </Form>}

    </>)

}
export default Profile;