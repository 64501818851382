import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const Warranty = function () {


    useEffect(() => {

        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        
        <div className="container">
                    <div className="row">
                    <div className="col-md-1"></div>
                        <div className="col-md-10">
                         <div className="pad-csa-boder">
                         <h1 className="titektre">Warranty Policy </h1>
                         <h3 className="policty">We, at Dekobuddy  stand behind the quality of our rugs and want you to be completely satisfied with your purchase. Below are the terms and conditions of our warranty policy for different varieties of rugs:</h3>
                         <h3 className="policty">Warranty Period:</h3>
                         <h3 className="policty">What's Covered</h3>
                           <ul>
                           <li>Manufacturing defects such as stitching issues, fraying, or material flaws.</li>
                           <li>Premature wear under normal usage conditions.</li>
                           <li>Color fading or discoloration that occurs within the warranty period.</li>
                           </ul>
                           <h3 className="policty">What's Not Covered</h3>
                           <ul>
                           <li>Damage caused by improper use, accidents, or negligence.</li>
                           <li> Normal wear and tear over time.</li>
                           <li>Damage resulting from improper cleaning or maintenance.</li>
                           <li>Changes in rug appearance due to exposure to sunlight or humidity.</li>
                           </ul>
                           <h3 className="policty">How to Claim Warranty</h3>
                           <ul>
                           <li>To claim warranty service, please contact our customer service team at dekobuddy @gmail.com / 9935036578 within the warranty period.</li>
                           <li>Provide proof of purchase (invoice or receipt) and details of the issue with the rug.</li>
                           <li>Our team will assess the claim and may request additional information or photos for evaluation.</li>
                           <li>If the issue is covered under warranty, we will either replace the rug with a new one or provide repair services, at our discretion.</li>
                           </ul>
                           <h3 className="policty">Replacement/Repair</h3>
                           <ul>
                             <li>If a replacement is chosen, the same or a similar rug will be provided, depending on availability.</li>
                             <li>If a repair is chosen, the rug will be repaired by our skilled craftsmen using appropriate materials.</li>
                           </ul>
                           <h3 className="policty"> Shipping Costs</h3>
                           <ul>
                             <li>Customers are responsible for shipping costs associated with returning the defective rug to us for inspection.</li>
                             <li>If the claim is approved, we will cover the shipping costs for sending the replacement rug or repaired rug back to the customer.</li>
                           </ul>
                           <h3 className="policty">Limitations</h3>
                           <ul>
                            <li>Our warranty applies only to the original purchaser and is non-transferable.</li>
                            <li>Any repairs or replacements made under warranty do not extend the original warranty period.</li>
                            <li>We reserve the right to determine whether a defect is covered under warranty and the appropriate remedy.</li>
                           </ul>
                            <h3 className="policty">Exclusive Remedy:</h3>
                           <ul>
                            <li>This warranty is the sole and exclusive remedy for defects in our rugs. We shall not be liable for any incidental or consequential damages arising from the use of our products.</li>
                           </ul>
                           <h3 className="policty">Jurisdiction</h3>
                           <ul>
                            <li>This warranty policy is governed by the laws of India, and any disputes shall be resolved in the courts of Bhadohi (U.P),India.</li>
                            <li>By purchasing our rugs, you agree to abide by the terms and conditions of this warranty policy. If you have any questions or need further clarification, please don't hesitate to contact us.</li>
                            
                           </ul>
                           <a href="mailto:dekobuddy@gmail.com">dekobuddy@gmail.com</a>/<a href="tel:919935036578">+919935036578</a>
                         </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )

}
export default Warranty