import Header from '../Header/Header';
import Footer from '../Footer/index';

import CartButton from './CartButton';
import { getProducts } from '../../services/product.service';
import customerObj from '../../utility/customerClass'
import { Skeleton, Pagination,Empty, notification } from 'antd';
import cartobj from '../../utility/cartClass';
import React, { useState, useEffect } from 'react';
import productObj from '../../utility/productClass'
import commonObj from '../../utility/commonclass'
import authObj from '../../utility/authclass';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import FilterComponent from './Filter';
import WishList from './Wishlist';
import NotifyButton from './NotifyButton';
const ProductSearchList = function () {
    //console.log('ll');
    const [searchParams, setSearchParams] = useSearchParams();    
    const [filtersort, selectedsort] = useState({orderby:'productName',type:'ASC'});  
    const [productListData, setProductList] = useState({ totalrecord: 0, list: [], url: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 12 });
    const [filter, setFilter] = useState({categories:[],price:[] });
    const [componentLoader,setcomponentLoader]=useState(0);
  
    let navigate = useNavigate();
    const searchkey=searchParams.get('s');


    useEffect(() => { 
        if(commonObj.isEmpty(searchkey)==false){
            getProducts({ page: paging.currentpage, limit: paging.itemlimit, filter: { searchkey: searchkey },order:filtersort }).then(list => {
                setcomponentLoader(1);
                setProductList(() => {
                    return { totalrecord: list.productdata.count, list: list.productdata.rows, url: list.url };
                })
            });
        }else{
            setcomponentLoader(1)
        }       
       
        window.scrollTo(0, 0);
           
    }, [paging,searchkey]);

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }
        })
    }

  
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }

       

    return (
        <>
       
            <Header searchkey={filter.searchkey}></Header>
            <section className="shop-details pt-90 pb-90">

                <div className="container">
                    <div className="row">
                        
                        <div className="col-xxl-12 col-xl-12">
                            <div className="shop-top-area mb-20">
    <h2> { commonObj.isEmpty(searchkey)==false && `Product Search For "${searchkey}"`}</h2>
                            </div>
                            
                            <div className="shop-main-area mb-40">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="tab1">
                                        <div className="row">

                                            {

                                                productListData.list.map((item, itemkey) => {
                                                 
                                                   
                                                    return (<div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6" key={item.id}>
                                                        <div className="product__item mb-20">
                                                            <div className="product__thumb w-img fix">
                                                            <Link to={`/product/` + item.productSku}>
                                                                {
                                                                    productObj.productImage(item, productListData.url,)
                                                                }
                                                            
                                                            </Link>   
                                                                <div class="product__action transition-3">
                                                                    <ul>
                                                                        <li>
                                                                            <WishList id={item.id} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="product__content">
                                                            <div className="product__content_box">
                                                                <div className="product__tag product__tag-4">
                                                                    <span className="sku-box"> SKU : <Link to={`/product/` + item.productSku}>{productObj.displaySKU(item)}</Link></span>
                                                                </div>
                                                                {cartobj.displayCondition(item)}
                                                                
                                                               
                                                                <h3 className="product__title">
                                                                    <Link to={`/product/` + item.productSku}>{item.productName}</Link>
                                                                </h3>
                                                                {productObj.displayPriceQuantity(item)}
                                                                </div>
                                                                <div className="product__select-button">
                                                                    {item.productPrice > 0 &&  item?.productQuantity > 0 && <CartButton product={{ ...item, productImage: ((item.productImage) ? productListData.url + item.productImage : "") }} classes="select-btn w-100"></CartButton>}
                                                                    {(item.productPrice == 0 || item?.productQuantity == 0) && <NotifyButton classes="select-btn w-100" item={item}></NotifyButton>}

                                                                </div>

                                                                {

                                                                }

                                                            </div>
                                                        </div>

                                                    </div>)

                                                })

                                            }

                                            {productListData.list.length == 0 && componentLoader!=0 && <Empty/>}

                                            { componentLoader==0 && <Skeleton />}



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-pagination text-center">
                                <nav>
                                    <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={productListData.totalrecord} onChange={handlePageChange} />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default ProductSearchList