import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
//import Axios from "axios"
import {  Switch, Pagination,Empty, notification } from 'antd';
import categoryObj from '../../utility/productClass'
  
export default function Category(){
     const [catlist,setList]=useState({list:[],count:0}); 
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
     const [filter, setFilter] = useState({  });  
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
   
     useEffect(()=>{          
          async  function  getCategoryData(){
               const data =await categoryObj.getCategoryPagination({ page: paging.currentpage, limit: paging.itemlimit, filter: filter },{headers: {"Authorization" : `Bearer ${token}`} });
               console.log(data);
               setList({list:data.categorydata.rows,count:data.categorydata.count});
          }
          getCategoryData();
     },[paging])

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     }    
     const statusChange = async (category,status) => {
          const data = {category:category,status:status}  
          const response =await categoryObj.activateHomeCategory(data);   
         // if(response.status == 1){
          
              // this.getCategoryData();
          //}
     };  
     
     const onChange = async  (id,checked) => {
          console.log(`switch id ${id}`);
          console.log(`switch to ${checked}`);
          const data = {category:id,status:(checked==true)?1:0}  
          const response =await categoryObj.activateHomeCategory(data);   
          notification.success({message:'Update successfully'})
        };
          return (
               <div>
                 
                     <table className="table table-light">
                         <thead>
                         <tr>
                              <th scope="col">Sr No</th>
                              <th scope="col">Category Name</th>
                              <th scope="col">Active Home</th>
                         </tr>
                         </thead>
                         <tbody>
                              {
                              catlist.list.length > 0 && catlist.list.map((catdata,sr) => {
                                   return(
                                   <tr>
                                        <td>{sr+1}</td>
                                        <td>{catdata.categoryName}</td>
                                        <td>
                                        <Switch defaultChecked={catdata.isHome} onChange={(e)=>onChange(catdata.id,e)} />
                                           </td>
                                        
                                   </tr>)
                                   
                              })}                         
                         </tbody>
                     </table>
                 <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={catlist.count} onChange={handlePageChange} />
              
               </div>  
          )   
}
