let Footer=()=>{

return (
    <footer className="footer">
        <div><a href="">DesireApplication</a> © 2022 .</div>
       
      </footer>

)

}

export default Footer; 