import { useDispatch } from 'react-redux';
import HeaderLogo from './HeaderLogo'
import HeaderCart from './HeaderCart'
import { Link, useNavigate } from 'react-router-dom'
import { emptySession } from '../../redux/action/userAction';
import HeaderSearch from './HeaderSearch'
import HeaderTop from './HeaderTop'
import React, { useState, useEffect } from 'react';
import productObj from '../../utility/productClass'
import AuthObj from '../../utility/authclass';
import { DownOutlined, UserOutlined,CaretRightOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip, Menu } from 'antd';
import config from '../../config/index'


var bgimg1 = require("../../images/choosus/IMG-20240131-WA0196 SLIDER.jpg");
const Header = function (props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [categorylist, setlist] = useState({ list: {}, url: "" });
    const [menuList,setMenu]=useState(["2","3","4","5","6","7","8"])


    useEffect(() => {
        async function getCategoryData() {
            //{ isHome: 1 }
            const data = await productObj.getCategory({orderby:"parent",ordertype:"ASC",isMenuOrder:true});
            console.log("category",data);
            let menuData={}
            for(let i of data?.category){
                console.log(i);
                if(i?.parent==0){
                    menuData[i?.id]={...i,child:[]} 
                }else if(menuData[i?.parent] !=undefined){
                    menuData[i?.parent]?.child.push(i);

                }

            }
            console.log(menuData)

            setlist({ list: menuData, url: data.url });

        }

        getCategoryData();

    }, [])


    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        console.log('click', e.key);
        if (e.key == 'profile') {

            navigate("/customer")
        } else if (e.key == 'logout') {
            AuthObj.logout();
            dispatch(emptySession());
            navigate("/")

        } else if (e.key == 'myorder') {
            navigate("/customer/myorder")
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="profile">
                Profile
          </Menu.Item>

            <Menu.Item key="myorder">
                My Order
          </Menu.Item>
            <Menu.Item key="logout">
                Logout
          </Menu.Item>
        </Menu>
    );

    const submenu=(mdata)=>{

        return(<ul className="drop-down level3 bciccomul">
            {mdata?.length > 0 && mdata.map((item) => {
                                    return ( <li className="">
                                    <Link to={productObj.categoryUrl(item)}>{item?.categoryName}</Link>
                                  </li>)
                                })
                            }
        </ul>)

    }

    return (<>
        <header>
            <div className="header__area">
                <HeaderTop></HeaderTop>

                <div className="header__bottom Headerinner" id="myHeader">
                
                   <div className="container">
                       <div className="row align-items-center">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-3 col-3">
                                <div className="category__menu d-flex align-items-center">
                                    <div className="category__btn mr-20">
                                        <button className="cat-btn" type="button"><i className="fa fa-bars"></i><span class="cat_name">All Categories</span></button>
                                        <div className="side-submenu transition-3">
                                        <div className="">
                                            <nav>
                                               
                                                <ul>
                                                <h4 className="mobileactinfomore">Explore</h4>
                                                  {Object.keys(categorylist?.list).map((item) => {
                                                        return (<li key={categorylist?.list[item]?.id} className={categorylist?.list[item]?.child?.length > 0?"has-dropdown drop":""}>
                                                            <Link to={productObj.categoryUrl(categorylist?.list[item])}>
                                                                                                                        
                                                                {categorylist?.list[item]?.categoryName}                                                               
                                                                {categorylist?.list[item]?.child !=null && categorylist?.list[item]?.child !=undefined && categorylist?.list[item]?.child?.length > 0 && <CaretRightOutlined />}
                                                            </Link>
                                                            {categorylist?.list[item]?.child?.length > 0 && submenu(categorylist?.list[item]?.child)}
                                                        </li>)
                                                    })}
                                                    

                                                </ul>
                                                <ul className="mobile-mneuubtop">
                                                <li><Link to="/track-order">Track Your Order</Link></li>
                                                <li><Link to="/shipping-delivery">Shipping & Return Policy  </Link></li>
                                                <li><Link to="/warranty">Warranty Policy</Link></li>
                                                <li><Link to="/track-order">Customisation</Link></li>
                                                </ul>

                                                <ul className="mobile-mneuub">
                                                <li className="abou-in-mobile"><Link to="/who-we-are">Who We Are</Link></li>
                                                <li className="abou-in-mobile"><Link to="/#whychooseus">Why Choose Us</Link></li>
                                                <h4 className="contactinfomore">Contact Info</h4>
                                                <li><p><i class="fa fa-phone" aria-hidden="true"></i> <Link to={`tel:${config.mobile}`}>{config.mobile}</Link></p></li>
                                                <li><p><i class="fa fa-envelope" aria-hidden="true"></i> <Link to={`tel:${config.mobile}`}>{config.email}</Link></p></li>
                                                </ul>
                                            </nav>
                                            </div>
                                        </div>
                                    </div>
                                     <div className="category__btn mobile-menu-seraceing1">
                                        
                                        <p className="wishlist-box ">  <Link className='shopping-item' to=""><i class="fa fa-map-marker" aria-hidden="true"></i></Link></p>
                                    </div>

                                    <div className="category__btn mobile-menu-seraceing1">
                                        <button className="shopping-item" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        <div className="side-submenu vadsckncjncij search-icons transition-3">
                                        <div className="search-engring">
                                            <nav>
                                                <ul>  
                                                  <HeaderSearch></HeaderSearch>
                                                </ul>
                                            </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                                <HeaderLogo></HeaderLogo>
                            </div>
       
                           

                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-3 col-3">
                            <div className="d-flex align-items-center alichusb"> 
                             <div className="category__btn mobile-menu-seraceing">
                                        <button className="shopping-item" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        <div className="side-submenu search-icons transition-3">
                                        <div className="search-engring">
                                            <nav>
                                                <ul>  
                                                  <HeaderSearch></HeaderSearch>
                                                </ul>
                                            </nav>
                                            </div>
                                        </div>
                                    </div>
                            <p className="wishlist-box ">  <Link className='shopping-item' to=""><i class="fa fa-heart-o" aria-hidden="true"></i></Link></p>
                          
                                <HeaderCart></HeaderCart>
                              
                                <div className="header__top-info mnbvcxertyui text-center">
                                {AuthObj.isLogin() == false && <Link className='shopping-item' to="/login"><i class="fa fa-user-circle-o" aria-hidden="true"></i></Link>}
                                {AuthObj.isLogin() == true && <>
                                    <Dropdown.Button overlay={menu} icon={<UserOutlined></UserOutlined>}>
                                        {AuthObj.getCurrentName()}
                                    </Dropdown.Button>  
                                </>}
                               
                                </div>
                                </div>   

                            </div>
                   
                        </div>
                    </div>
                </div>
            </div>
        </header>

    </>)

}
export default Header;