import Header from '../Header/Header';
import Footer from '../Footer/index';
import React, { useState, useEffect } from 'react';


const PrivacyPolicy = function () {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])


    return (
        <>
            <Header ></Header>
            <section className="shop-details pt-90 pb-90">
        
                <div className="container">
                    <div className="row">
                    <div className="col-md-1"></div>
                        <div className="col-md-10">
                        <div className="pad-csa-boder">
                        <h1 className="">Privacy Policy</h1>
                        <h3>Welcome to Dekobuddy! We value your trust and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information on our website.</h3>
                        <h3>Information We Collect</h3>
                        <p className="content-p">Personal Information: When you visit our website, we may collect personal information such as your name, email address, phone number, and address if you choose to provide it voluntarily, such as when you make a purchase or sign up for our newsletter.</p>
                        <p className="content-p">Usage Information: We may also collect non-personal information about your visit to our website, including your IP address, browser type, pages visited, and the duration of your visit. This information helps us improve our website and tailor our services to better meet your needs.</p>
                        <h3>How We Use Your Information</h3>
                        <p className="content-p">To Process Orders: We use your personal information to process and fulfill orders, communicate with you about your purchase, and provide customer support.</p>
                        <p className="content-p">To Improve Our Services: We may use aggregated usage information to analyze trends, monitor the effectiveness of our marketing efforts, and improve the functionality of our website.</p>
                        <p className="content-p">To Send Marketing Communications: With your consent, we may send you promotional emails about new products, special offers, and other updates. You can opt-out of receiving these communications at any time.</p>
                        <h3>Data Security</h3>
                        <p className="content-p">We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                        <h3>Sharing Your Information</h3>
                        <p className="content-p">Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you. These providers are contractually obligated to keep your information confidential and are prohibited from using it for any other purpose.</p>
                        <p className="content-p">Legal Requirements: We may disclose your information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                        <p className="content-p"> Information held by us. You can update your information by logging into your account or contacting us directly.</p>
                        <p className="content-p">Data Retention: We will retain your personal information for as long as necessary to fulfill the purposes outlined.</p>
                        <h3>Your Rights</h3>
                        <p className="content-p">Access and Correction: You have the right to access and correct your personal information held by us. You can update your information by logging into your account or contacting us directly.</p>
                        <p className="content-p">Data Retention: We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                        <h3>Security Measures</h3>
                        <p className="content-p">We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                        <p className="content-p">However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
                        <p className="content-p">You have the right to access, update, or delete your personal information. If you would like to exercise any of these rights, please contact us at:<a href="mailto:dekobuddy@gmail.com"> dekobuddy@gmail.com</a></p>
                        <h3>Cookies</h3>
                        <p className="content-p">Our website uses cookies to enhance your browsing experience. You can choose to disable cookies through your browser settings, but please note that some features of our website may not function properly.</p>
                        <h3>Updates to this Privacy Policy</h3>
                        <p className="content-p">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.</p>
                        <h3>Contact Us</h3>
                        <p className="content-p">If you have any questions or concerns about our Privacy Policy, please contact us at: <a href="mailto:dekobuddy@gmail.com">dekobuddy@gmail.com</a></p>
                        <p className="content-p">By using our website, you consent to the terms of this Privacy Policy.</p>
                        </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default PrivacyPolicy